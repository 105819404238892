import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';
import RiskBand from 'app/components/RiskBand';
import Table from 'app/components/Table';
import { HEADER_ITEMS, RISK_SCORE_METHODOLOGY } from './constants';
import { getMinWidthForHoldings } from './utils';
import HoldingsTableRow from 'app/pages/RecommendedPortfolio/Snapshot/HoldingsTableRow';

interface AppendixProps {
  headerTitle?: string;
}

const mainTitleStyles = tw(
  'text-default font-medium text-[0.875rem] leading-4 font-inter',
);
const boldHeadingsStyles = tw(
  `text-neutral600 font-semibold text-xs leading-4 font-inter`,
);

const contentStyles = tw(
  'text-neutral600 font-normal text-xs leading-5 font-inter',
);

const viewStyles = tw('my-4');

function Appendix({ headerTitle = 'Appendix' }: AppendixProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={viewStyles}>
        <Text style={mainTitleStyles}>
          TIFIN Wealth’s Risk Scoring Methodology
        </Text>
      </View>
      <View>
        <Text style={[boldHeadingsStyles, tw('mb-3')]}>Overview</Text>
      </View>
      <View>
        <Text style={contentStyles}>
          TIFIN Wealth Risk helps advisors gain a more complete view of a
          client's risk profile by comparing 3 risk scores- Risk Capacity, Risk
          Preference, and Portfolio Risk.
        </Text>
      </View>
      <View style={tw('my-4 ml-3')}>
        <View>
          <Text style={boldHeadingsStyles}>
            Risk Capacity -
            <Text style={contentStyles}>
              &nbsp;how much risk the investor can or cannot take given their
              current life situation - the ability to take risk.
            </Text>
          </Text>
        </View>
        <View>
          <Text style={boldHeadingsStyles}>
            Risk Preference -
            <Text style={contentStyles}>
              &nbsp;how much risk the investor is willing to take.
            </Text>
          </Text>
        </View>
        <View>
          <Text style={boldHeadingsStyles}>
            Portfolio Risk -
            <Text style={contentStyles}>
              &nbsp;a calculation of an investment portfolio's exposure to
              potential risk.
            </Text>
          </Text>
        </View>
      </View>
      <View style={viewStyles}>
        <Text style={[boldHeadingsStyles, tw('mb-3')]}>Why Three Scores?</Text>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            Risk tolerance is defined as "a customer's ability and willingness
            to lose some or all of the original investment in exchange for
            greater potential returns."
          </Text>
        </View>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            We believe the prevalent practice of ONLY measuring an investor's
            feelings toward risk (risk preference) is insufficient, as this can
            be quite different than the losses the client can withstand given
            their unique personal and financial situations.
          </Text>
        </View>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            Designed to address compliance needs, our questionnaire calculates a
            client's Risk Capacity and Risk Preference. We then use a unified
            metric for the client's Portfolio Risk. By measuring all three with
            a common methodology, we can make an apples-to-apples comparison of
            all three scores.
          </Text>
        </View>
      </View>
      <View style={viewStyles}>
        <Text style={[boldHeadingsStyles, tw('mb-3')]}>
          How TIFIN Wealth’s Risk Approach Is Different
        </Text>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            Some risk tolerance tools only address one aspect of risk at any
            given time. Some look at risk preference (a risk score based on an
            investor's feelings about risk) and others base their score on a
            timeline toward retirement.
          </Text>
        </View>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            In contrast, we separate Risk Capacity, Risk Preference, and the
            Portfolio Risk as described above. Risk Capacity only changes as
            life changes and we believe is an objective, stable, and relevant
            anchor for accurate risk assessment. We do include psychological
            risk preferences, but the questions serve as a reference rather than
            the sole basis of advice.
          </Text>
        </View>
        <View style={tw('mt-2')}>
          <Text style={contentStyles}>
            The addition of our advanced Al and machine learning algorithms
            enables TIFIN Wealth Risk to ask fewer questions yet produces what
            we believe to be a more accurate risk score. Our short-form
            questionnaire streamlines the risk assessment process to under 13
            questions, which allows advisors to expedite the financial planning
            conversation.
          </Text>
        </View>
      </View>
      <View style={viewStyles} break>
        <Text style={mainTitleStyles}>
          TIFIN Wealth’s Risk Scoring Methodology
        </Text>
      </View>
      <View style={tw('mb-3')}>
        <Text style={boldHeadingsStyles}>Forward Looking Assumptions</Text>
        <View style={tw('mt-1')}>
          <Text style={contentStyles}>
            The scores are calculated utilizing forward looking returns for all
            securities based on:
          </Text>
        </View>
      </View>
      <View style={tw('mb-3')}>
        <Text style={contentStyles}>
          1. The expected return and volatility of various asset classes. The
          Capital Market Assumptions (CMAs) represent an average of the capital
          market assumptions reported across several asset managers including JP
          Morgan, Morgan Stanley, BNY Mellon, Wells Fargo, and BlackRock to get
          a "wisdom of the experts" estimate. Each of these managers have
          significant teams working on these capital market assumptions that
          underlie several important decisions for them and their investors
          alike. Forecasts are updated on an annual basis. A full list of CMA
          sources is available upon request.
        </Text>
      </View>
      <View style={tw('mb-3')}>
        <Text style={contentStyles}>
          2. Each security's exposure to its asset class. We estimate the
          exposure through a rolling 5-year regression between the security and
          its primary asset class.
        </Text>
      </View>
      <View style={tw('mb-3')}>
        <Text style={contentStyles}>
          3. TIFIN Wealth uses parametric Value-at-Risk (VaR) to calculate the
          portfolio’s Risk Score. VaR is an industry standard measure of risk;
          it measures the potential risk of loss for an investment or portfolio
          over a given period of time with a specific confidence level. TIFIN
          Wealth uses a 1-year VaR at a 99% confidence level to calculate the
          Risk Score. This means over a 1-year period there is a 99% probability
          that the portfolio will not lose more than the Risk Score. This also
          means over a 1-year period there is a 1% probability that the
          portfolio could lose more than the Risk Score.
        </Text>
      </View>
      <View style={viewStyles}>
        <Text style={[boldHeadingsStyles, tw('mb-3')]}>
          Understanding the Risk Score
        </Text>
        <Text style={contentStyles}>
          Ideally, an investor's portfolio would fall between the Risk Capacity
          and Risk Preference scores on what TIFIN Wealth Risk calls the Risk
          Band. With TIFIN Risk, advisors are able to easily model alternate
          portfolios to develop a more favorable investment strategy for their
          clients.
        </Text>
        <View style={tw('my-20')}>
          <RiskBand
            riskData={{
              riskCapacity: 45,
              riskPreference: 27,
            }}
            riskScores={[
              {
                value: 35,
              },
            ]}
          />
        </View>
        <View style={viewStyles}>
          <Text style={contentStyles}>
            In this example, the client portfolio scored a 35. Based on our
            calculations, there is a 1% chance that the portfolio can lose more
            than 35% over the next 12 months. This client prefers not to see
            more than a 27% loss, but they have a risk capacity of 45. In other
            words they could recover from a 45% drawdown. This portfolio appears
            to suit their risk tolerance band.
          </Text>
        </View>
      </View>
      <View style={viewStyles} break>
        <Text style={mainTitleStyles}>
          TIFIN Wealth’s Risk Scoring Methodology
        </Text>
      </View>
      <View>
        <Text style={contentStyles}>
          To help illustrate TIFIN Wealth's Risk Scores, here is a sample of
          common securities and a sample portfolio. The sample portfolio is
          using a 60% weight in the S&P 500 ETF Trust™ (SPY)and a 40% weight in
          iShares Core U.S. Aggregate Bond Index™ (AGG).
        </Text>
      </View>
      <View style={tw('my-4')}>
        <View
          style={tw(
            'p-4 border-t-[1px] border-r-[1px] border-l-[1px] rounded-tl-[8px] rounded-tr-[8px] border-[#D7D7D7] border-solid',
          )}
        >
          <Text style={tw('text-xl font-medium')}>Sample Risk Scores</Text>
        </View>
        <Table
          tableHeaders={HEADER_ITEMS}
          tableData={RISK_SCORE_METHODOLOGY}
          getMinWidth={getMinWidthForHoldings}
          CustomTableRow={HoldingsTableRow}
          totalFields={{
            types: ['WEIGHT', 'PRICE'],
            values: ['weight', 'price'],
          }}
          showTotal={false}
        />
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default Appendix;

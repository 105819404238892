import newWealthLogo from 'assets/newTifinWealthFavicon.png';
import customLogo from 'assets/customModelLogo.png';

const HTTPS_PREFIXES = ['https://', 'http://'];

export const getImageUrl = (url: string) => {
  const PLANIQ_BASE_URL = 'https://assets.planiq.com/funds/';
  if (!url) return '';
  const findPrefix = HTTPS_PREFIXES.find(prefix => url.includes(prefix));
  if (findPrefix) {
    const splittedURLs = url.split(findPrefix).map(item => findPrefix + item);
    if (splittedURLs.length) {
      const lastItem = splittedURLs[splittedURLs.length - 1];
      return lastItem;
    }
    return url;
  }

  return PLANIQ_BASE_URL + url;
};

export const formatToMillions = labelValue => {
  // Nine Zeroes for Billions
  return Math.abs(Number(labelValue)) >= 1.0e9
    ? (Math.abs(Number(labelValue)) / 1.0e9)?.toFixed(2) + 'B'
    : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? (Math.abs(Number(labelValue)) / 1.0e6)?.toFixed(2) + 'M'
      : // Three Zeroes for Thousands
        Math.abs(Number(labelValue)) >= 1.0e3
        ? (Math.abs(Number(labelValue)) / 1.0e3)?.toFixed(2) + 'K'
        : Math.abs(Number(labelValue)).toFixed(2);
};

const portMapping = {
  Port1: 'EQ 0% / FI 21% / ALT 17% / CASH 62%',
  Port2: 'EQ 0% / FI 33% / ALT 24% / CASH 43%',
  Port3: 'EQ 0% / FI 48% / ALT 29% / CASH 23%',
  Port4: 'EQ 2% / FI 65% / ALT 30% / CASH 3%',
  Port5: 'EQ 10% / FI 60% / ALT 30% / CASH 0%',
  Port6: 'EQ 17% / FI 53% / ALT 30% / CASH 0%',
  Port7: 'EQ 23% / FI 47% / ALT 30% / CASH 0%',
  Port8: 'EQ 29% / FI 41% / ALT 30% / CASH 0%',
  Port9: 'EQ 35% / FI 35% / ALT 30% / CASH 0%',
  Port10: 'EQ 40% / FI 30% / ALT 30% / CASH 0%',
  Port11: 'EQ 44% / FI 26% / ALT 30% / CASH 0%',
  Port12: 'EQ 52% / FI 18% / ALT 30% / CASH 0%',
  Port13: 'EQ 60% / FI 10% / ALT 30% / CASH 0%',
  Port14: 'EQ 66% / FI 4% / ALT 30% / CASH 0%',
  Port15: 'EQ 70% / FI 0% / ALT 30% / CASH 0%',
  Port16: 'EQ 69% / FI 0% / ALT 31% / CASH 0%',
  Port17: 'EQ 70% / FI 0% / ALT 30% / CASH 0%',
};

export const isModelTypeCustom = baseModelData => {
  return ['default_logo', 'custom'].includes(baseModelData?.sponsorLogo);
};

export const checkForNoModels = (baseModelData, advisorLogo = '') => {
  if (baseModelData?.seriesName === 'Optimized') {
    return {
      name: portMapping[baseModelData?.modelName],
      image: newWealthLogo,
    };
  }
  if (baseModelData?.sponsorLogo && !isModelTypeCustom(baseModelData)) {
    return {
      name: baseModelData?.name,
      image: getImageUrl(baseModelData?.sponsorLogo),
    };
  } else {
    return {
      name: baseModelData?.modelName,
      image: advisorLogo || customLogo,
    };
  }
};

export const formatDigitUptoDecimalPlaces = (
  num: number,
  decimalPlaces = 2,
) => {
  if (!num) return '0';
  return num.toLocaleString('en-US', {
    maximumFractionDigits: decimalPlaces,
    minimumFractionDigits: decimalPlaces,
  });
};

export const displayPortfolioData = (data: any[] = [], key = '') => {
  const firstItem = data?.[0] ?? {};
  return (
    Array.isArray(data) &&
    data.length > 0 &&
    Object.keys(firstItem).includes(key) &&
    firstItem?.[key]
  );
};

export const getQueryParams = () => {
  return new URLSearchParams(window.location.search);
};

export const isParamContainsSage = () => {
  const params = getQueryParams();
  return !!params.get('is_sage');
};

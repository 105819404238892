import React from 'react';
import ReactPDFChart from 'react-pdf-charts';
import { Text, View } from '@react-pdf/renderer';
import { Area, AreaChart, CartesianGrid, XAxis, YAxis } from 'recharts';

import { tw } from 'utils/tailwindSetup';
import { formatToMillions } from 'utils/helpers';
import { ProjectedGrowthProps } from './types';

const CustomDot = props => {
  const { cx, cy, payload } = props;

  if (payload?.date === 'Today') {
    return <circle cx={cx} cy={cy} r={4} fill="#7B73E4" width={8} height={8} />;
  }

  return null;
};

function ProjectedGrowth({
  data = [],
  overrideXAxisInterval,
  width = 300,
  height = 300,
  chartMargin = {
    top: 10,
    left: 0,
    bottom: 0,
    right: 0,
  },
  isPresentInPotentialReturns = false,
}: ProjectedGrowthProps) {
  if (!data || !Array.isArray(data) || !data?.length) return <></>;

  const amountLabel = value => {
    return `$${formatToMillions(Number(value)?.toFixed(2))}`;
  };
  const lastItem = data?.length - 1;

  const bandRanges = [
    {
      title: 'Lower Band',
      key: 'lowerBand',
    },
    {
      title: 'Mean Band',
      key: 'meanBand',
    },
    {
      title: 'Upper Band',
      key: 'upperBand',
    },
  ];

  return (
    <View style={tw('w-full h-full flex flex-col')}>
      <View style={tw('flex flex-row justify-between items-start mb-3')}>
        {bandRanges.map((bandRange, index) => (
          <View key={index}>
            <Text
              style={tw(
                `font-inter text-xs leading-6 ${isPresentInPotentialReturns ? 'text-black my-2.5' : 'text-neutral400'}`,
              )}
            >
              {bandRange?.title ?? ''}
            </Text>
            <Text
              style={tw(
                `font-inter text-base font-normal leading-6 text-xs ${isPresentInPotentialReturns ? 'text-black' : 'text-neutral500'} `,
              )}
            >
              {amountLabel(data?.[lastItem]?.[bandRange?.key]) ?? 0}
            </Text>
          </View>
        ))}
      </View>
      <ReactPDFChart>
        <AreaChart
          data={data ?? []}
          margin={chartMargin}
          height={height}
          width={width}
        >
          <CartesianGrid horizontal vertical={false} stroke="#98A2B3" />
          <XAxis
            dataKey="date"
            fontSize={10}
            interval={overrideXAxisInterval || 'preserveEnd'}
            tick={{
              fontSize: 10,
              fill: '#98A2B3',
            }}
            tickLine={{ stroke: '#98A2B3' }}
            tickCount={4}
          />

          <YAxis
            fontSize={10}
            tickCount={5}
            axisLine={false}
            tick={{ fill: '#98A2B3' }}
            tickLine={{ stroke: '#98A2B3' }}
            domain={[
              parseInt(data?.[lastItem]?.lowerBand?.toString()),
              parseInt(data?.[lastItem]?.upperBand?.toString()),
            ]}
            tickFormatter={amountLabel}
          />
          <Area
            type="monotone"
            dataKey="upperBand"
            fillOpacity={0.5}
            fill="#F6F2FF"
            stroke="#7B73E4"
            isAnimationActive={false}
          />
          <Area
            type="monotone"
            dataKey="meanBand"
            stroke="#7B73E4"
            fill="#F6F2FF"
            fillOpacity={0.5}
            strokeWidth={2}
            isAnimationActive={false}
          />
          <Area
            type="monotone"
            dataKey="lowerBand"
            stroke="#7B73E4"
            fill="#F6F2FF"
            fillOpacity={0.5}
            isAnimationActive={false}
            dot={<CustomDot payload={data} />}
          />
        </AreaChart>
      </ReactPDFChart>
    </View>
  );
}

export default ProjectedGrowth;

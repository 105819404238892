import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';

interface GeneralDisclosureProps {
  disclosures?: string;
  headerTitle?: string;
}

function GeneralDisclosures({
  disclosures = '',
  headerTitle = 'Disclosures',
}: GeneralDisclosureProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('mb-[3.125rem]')}>
        <Text
          style={tw(
            'font-normal text-[0.6875rem] text-neutral600 font-inter mb-5',
          )}
        >
          {disclosures}
        </Text>
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default GeneralDisclosures;

import React from 'react';
import { CurrentHoldingsProps } from './types';
import ProposalPage from 'app/components/ProposalPage';
import { View, Text } from '@react-pdf/renderer';
import Table from 'app/components/Table';
import { format } from 'date-fns';
import { tw } from 'utils/tailwindSetup';
import { HEADER_ITEMS } from './constants';
import { getMinWidthForHoldings } from './utils';
import HoldingsTableRow from '../../RecommendedPortfolio/Snapshot/HoldingsTableRow';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { isParamContainsSage } from 'utils/helpers';

function CurrentPortfolioHoldings({
  headerTitle = 'Current Portfolio',
  currentPortfolioHoldings,
  accountName = '',
  isAllAccountsSelected = true,
}: CurrentHoldingsProps) {
  const isSageDocument = isParamContainsSage();
  const displayAccountName = accountName.replace('Account', '').trim();

  return (
    <ProposalPage headerTitle={headerTitle}>
      <View
        style={tw('text-neutral600 font-normal text-[0.75rem] font-inter')}
        fixed
      >
        <Text>
          Current Holdings as of {format(new Date(), 'MM/dd/yyyy')}.{' '}
          {!isAllAccountsSelected ? (
            <Text>
              Account referenced is{' '}
              <Text style={tw('font-semibold')}>{displayAccountName}</Text>
            </Text>
          ) : (
            ''
          )}
        </Text>
      </View>

      <View
        style={tw(
          'mt-4 p-4 border-t border-r border-l rounded-tl-lg rounded-tr-lg border border-[#D7D7D7]',
        )}
        fixed
      >
        <Text style={tw('font-medium text-base')}>Holdings</Text>
      </View>

      <Table
        tableHeaders={HEADER_ITEMS}
        tableData={currentPortfolioHoldings}
        getMinWidth={getMinWidthForHoldings}
        CustomTableRow={HoldingsTableRow}
        totalFields={{
          types: ['WEIGHT', 'VALUE'],
          values: ['weight', 'value'],
        }}
      />

      <PageBottomContainer>
        <Disclosure
          text={[
            `Risk Scores shown are ${isSageDocument ? `Sage’s` : 'TIFIN Wealth’s'}  measure for how risky a particular holding is, with 1 being low risk and 99 being very risky. Market Value ($ Value) of each holding is calculated as of the report date using previous market close prices for each security multiplied by the shares held. Please see Appendix: ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} Risk Scoring Methodology. Risk Score assigned does not guarantee any level of performance or against loss of principal.`,
            `Neither Asset Allocation nor Diversification guarantee a profit or protect against a loss in a declining market. They are methods used to help manage investment risk.`,
            `Investing in international securities involves special additional risks. These risks include, but are not limited to, currency risk, political risk, and risk associated with varying accounting standards. Investing in emerging markets may accentuate these risks. Sector strategies subject the investor to increased industry-specific risks.`,
            `Pricing data is sourced from FactSet Research Systems, Inc. and Morningstar, Inc.`,
            `The information, data, analysis, and opinions contained in this report include the confidential and proprietary information of ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'}; may include or be derived from account information provided by you or your financial representative which cannot be verified by ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'}. This information may not be copied or redistributed; does not constitute investment advice and is provided solely for informational purposes only. This material does not constitute an offer to buy or sell a security. Product suitability must be independently determined for each individual investor. Except as otherwise provided by law, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} shall not be responsible for any trading decisions, damages or other losses resulting from the use of this information, data, analysis or opinion. This report is supplemental sales literature. If applicable, this report should be preceded or accompanied by a prospectus, or equivalent, and disclosure statement.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default CurrentPortfolioHoldings;

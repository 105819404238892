export const HEADER_ITEMS = [
    {
        label: 'ASSET CLASS',
        type: 'ASSET CLASS',
        valueKey: 'assetClass',
        fixedWidth: false,
    },
    {
        label: 'ASSET TYPE',
        type: 'ASSET TYPE',
        valueKey: 'assetType',
        fixedWidth: true,
    },
    {
        label: 'ASSET',
        type: 'ASSET',
        valueKey: 'asset',
        fixedWidth: false,
    },
    {
        label: 'EXPECTED RETURN',
        type: 'EXPECTED RETURN',
        valueKey: 'expectedReturn',
        fixedWidth: false,
    },
    {
        label: 'VOLATILITY',
        type: 'VOLATILITY',
        valueKey: 'volatility',
        fixedWidth: false,
    },
];

export const GOE_CMA_TABLE_DATA = [
    {
        assetClass: 'Equity',
        assetType: 'US Equity',
        asset: 'US Equities',
        expectedReturn: '5.00%',
        volatility: '15.32%',
    },
    {
        assetClass: 'Equity',
        assetType: 'US Equity',
        asset: 'US Small Cap Equities',
        expectedReturn: '5.20%',
        volatility: '20.21%',
    },
    {
        assetClass: 'Equity',
        assetType: 'International Equity',
        asset: 'EAFE Equities',
        expectedReturn: '5.49%',
        volatility: '16.53%',
    },
    {
        assetClass: 'Equity',
        assetType: 'International Equity',
        asset: 'EM Equities',
        expectedReturn: '6.87%',
        volatility: '21.20%',
    },
    {
        assetClass: 'Equity',
        assetType: 'US Equity',
        asset: 'REITS',
        expectedReturn: '5.30%',
        volatility: '21.03%',
    },
    {
        assetClass: 'Fixed Income',
        assetType: 'US Bonds',
        asset: 'US Treasury',
        expectedReturn: '1.80%',
        volatility: '4.38%',
    },
    {
        assetClass: 'Fixed Income',
        assetType: 'US Bonds',
        asset: 'US Corp',
        expectedReturn: '3.10%',
        volatility: '5.65%',
    },
    {
        assetClass: 'Fixed Income',
        assetType: 'US Bonds',
        asset: 'US HY',
        expectedReturn: '3.90%',
        volatility: '9.22%',
    },
    {
        assetClass: 'Fixed Income',
        assetType: 'International Bonds',
        asset: 'EM Bonds',
        expectedReturn: '5.40%',
        volatility: '9.34%',
    },
    {
        assetClass: 'Cash',
        assetType: 'US Cash',
        asset: 'US Cash',
        expectedReturn: '2.10%',
        volatility: '0.16%',
    },
];

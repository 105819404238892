import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { AssessmentsQnAProps } from './types';

const baseBorderStyle = 'border-b-[1.08968px] border-[#D7D7D7] border-solid';
function AssessmentsQnA({
  question,
  answer,
  index,
  noOfQuestions,
}: AssessmentsQnAProps) {
  const questionsPerPage = 8;
  // Determine whether to display a border based on the question's position.
  // Borders are displayed between questions unless it's the last question on the page or the very last question in the data.
  const shouldDisplayBorder =
    (index + 1) % questionsPerPage !== 0 && index + 1 !== noOfQuestions;

  return (
    <View
      style={tw(
        `py-[1.5rem] ${shouldDisplayBorder ? baseBorderStyle : 'border-none'}`,
      )}
      break={index > 0 && index % questionsPerPage === 0}
    >
      <View
        style={tw(
          'flex flex-row items-start font-inter font-normal text-[#525863] text-[1rem] break-words pr-8 leading-6',
        )}
      >
        <Text>{index + 1}. </Text>
        <Text>{question}</Text>
      </View>
      <View
        style={tw(
          'ml-5 mt-5 font-inter font-semibold text-[#525863] text-[1rem]',
        )}
      >
        <Text>{String(answer) ? String(answer) : ''}</Text>
      </View>
    </View>
  );
}

export default AssessmentsQnA;

import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';
import { Cell, Pie, PieChart } from 'recharts';

import { tw } from 'utils/tailwindSetup';
import { ModelDistributionListChartProps } from './types';

function ModelDistributionListChart({
  data,
  chartColors,
  subModelsLogo = [],
  firmLogo,
}: ModelDistributionListChartProps) {
  if (Array.isArray(data) && data?.length < 0) return <></>;

  return (
    <View style={tw('flex flex-row justify-between my-[1.6875rem]')}>
      <View style={tw('min-w-[65%]')}>
        {data.map((item, index) => {
          return (
            <View
              key={item?.id}
              style={tw(
                'flex flex-row gap-2 justify-evenly items-center mb-2.5',
              )}
            >
              <View>
                <View
                  style={tw(`w-4 h-4 rounded-sm bg-[${chartColors?.[index]}]`)}
                />
              </View>
              <View>
                <Image
                  style={tw('max-h-[3.125rem] w-[4.8125rem] object-contain')}
                  src={
                    Array.isArray(subModelsLogo) && subModelsLogo?.length > 0
                      ? subModelsLogo?.[index] || firmLogo
                      : firmLogo
                  }
                />
              </View>
              <View style={tw('w-52')}>
                <Text style={tw('text-default text-xs leading-tight')}>
                  {item?.name ?? ''}
                </Text>
              </View>
              <View>
                <Text
                  style={tw(
                    'text-default font-medium text-sm leading-[1.063rem]',
                  )}
                >
                  {item?.weight?.toFixed(2) ?? 0}%
                </Text>
              </View>
            </View>
          );
        })}
      </View>
      <View style={tw('h-[12.5rem] w-[25rem]')}>
        <ReactPDFChart>
          <PieChart height={200} width={400}>
            <Pie
              data={data}
              dataKey="weight"
              cx={120}
              cy={80}
              innerRadius={60}
              outerRadius={80}
              startAngle={90}
              endAngle={-270}
              isAnimationActive={false}
            >
              {data.map((_, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={chartColors?.[index % chartColors?.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </ReactPDFChart>
      </View>
    </View>
  );
}

export default ModelDistributionListChart;

export const HEADER_ITEMS = [
  {
    label: 'Symbol',
    type: 'SYMBOL',
    valueKey: 'ticker',
    fixedWidth: false,
  },
  {
    label: 'Name',
    type: 'NAME',
    valueKey: 'name',
    fixedWidth: true,
  },
  {
    label: 'Risk Score',
    type: 'RISK_SCORE',
    valueKey: 'riskScore',
    fieldType: 'component',
    fixedWidth: false,
  },
  {
    label: '$ Value',
    type: 'VALUE',
    valueKey: 'value',
    prefix: '$',
    fixedWidth: false,
  },
  {
    label: 'Weights',
    type: 'WEIGHT',
    valueKey: 'weight',
    fixedWidth: false,
    postfix: '%',
  },
];
import React, { useMemo } from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import ReactPDFChart from 'react-pdf-charts';
import { Cell, Pie, PieChart } from 'recharts';
import LossGainBand from 'app/components/LossGainBand';
import { tw } from 'utils/tailwindSetup';
import { PortfolioRiskScoreCardProps } from './types';

function PortfolioRiskScoreCard({
  portfolioType,
  riskScoreData,
  portfolioData,
  cardHeader = '',
  labelIcon,
  expenseRatio = 0,
  showExpenseRatio = false,
}: PortfolioRiskScoreCardProps) {
  const pieChartColor = useMemo(() => {
    return portfolioType === 'CURRENT' ? '#457F8F' : '#7B73E4';
  }, [portfolioType]);

  return (
    <View style={tw('w-full flex-1 border border-neutral200 rounded')}>
      <View style={tw('flex flex-row items-center p-4')}>
        {labelIcon ? <Image src={labelIcon} style={tw('w-4 h-4')} /> : <></>}
        <Text
          style={tw('font-inter font-medium text-xs text-default ml-1 min-h-4')}
        >
          {cardHeader}
        </Text>
      </View>
      <View style={tw('w-full p-3 rounded-[0.3125rem]')}>
        {portfolioData && Object.keys(portfolioData).length ? (
          <View style={tw('w-full flex justify-center items-center')}>
            <ReactPDFChart>
              <PieChart width={100} height={100}>
                <text
                  x={50}
                  y={50}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fontSize: 16,
                    fontWeight: '500',
                    color: '#101828',
                  }}
                >
                  {riskScoreData?.riskScore ?? 0}
                </text>
                <Pie
                  data={[
                    {
                      id: '1',
                      name: 'L1',
                      value: parseFloat(riskScoreData?.riskScore ?? 0),
                    },
                    {
                      id: '2',
                      name: 'L2',
                      value: 100 - parseFloat(riskScoreData?.riskScore ?? 0),
                    },
                  ]}
                  dataKey="value"
                  innerRadius={'85%'}
                  outerRadius="100%"
                  fill="#E4E7EC"
                  startAngle={90}
                  endAngle={-270}
                  paddingAngle={0}
                  blendStroke
                  isAnimationActive={false}
                >
                  <Cell key="test" fill={pieChartColor ?? '#27525E'} />
                </Pie>
              </PieChart>
            </ReactPDFChart>
          </View>
        ) : (
          <></>
        )}
        <View style={tw('flex flex-col items-center justify-center p-4 gap-3')}>
          <LossGainBand lossGain={riskScoreData?.lossGain ?? {}} />
        </View>

        {showExpenseRatio && expenseRatio && (
          <View style={tw('flex flex-col items-center justify-center')}>
            <Text style={tw('text-sm font-inter text-neutral500')}>
              Expense Ratio
            </Text>
            <Text style={tw('font-inter text-base mt-2 text-[#0B5CD7]')}>
              {expenseRatio?.toFixed(2) ?? 0}%
            </Text>
          </View>
        )}
      </View>
    </View>
  );
}

export default PortfolioRiskScoreCard;

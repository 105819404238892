import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { formatToMillions } from 'utils/helpers';
import { TotalProps } from './types';

function Total({
  totalFields,
  tableData,
  getMinWidth,
  tableHeaders,
}: TotalProps) {
  const currentTotal = useMemo(() => {
    return totalFields.values.reduce((currentTotal, currentValue) => {
      const newValue = tableData?.reduce(
        (acc, curr) =>
          acc + (!isNaN(+curr?.[currentValue]) ? +curr?.[currentValue] : 0) ??
          0,
        0,
      );
      currentTotal[currentValue] = newValue ?? 0;
      return currentTotal;
    }, {}) as typeof totalFields.values;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  const formatValueBasedOnKey = (valueKey: string) => {
    if (
      valueKey &&
      +currentTotal?.[valueKey] &&
      !isNaN(+currentTotal?.[valueKey])
    ) {
      const value = currentTotal?.[valueKey];
      if (valueKey === 'weight') {
        return 100;
      }
      return formatToMillions(value);
    }
    return '--';
  };

  return (
    <View
      style={tw(
        'w-full h-[2.1875rem] border border-t-0 border-neutral200 flex flex-row flex-nowrap',
      )}
    >
      {Array.isArray(tableHeaders) && tableHeaders.length > 0 ? (
        tableHeaders.map(
          (
            { type, valueKey, prefix = '', postfix = '', fixedWidth = false },
            index,
          ) => {
            return (
              <View
                key={index}
                style={tw(
                  `py-2 ${index === 0 ? 'pl-2' : 'pl-0'} font-semibold text-xs leading-[1.125rem] text-default min-w-[${getMinWidth(type)}] ${fixedWidth ? `w-[${getMinWidth(type)}]` : 'w-auto'}`,
                )}
              >
                {index === 0 ? (
                  <Text>Total</Text>
                ) : (
                  <Text>
                    {totalFields.types.includes(type)
                      ? `${prefix} ${formatValueBasedOnKey(valueKey)} ${postfix}`
                      : ''}
                  </Text>
                )}
              </View>
            );
          },
        )
      ) : (
        <></>
      )}
    </View>
  );
}

export default Total;

import React, { useMemo } from 'react';
import { G, Path, Svg, Text, View } from '@react-pdf/renderer';
import { format, isValid } from 'date-fns';
import LineGraph from 'app/components/LineGraph';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import { GRAPH_COLORS } from './constants';
import { tw } from 'utils/tailwindSetup';
import { HistoricalPerformanceProps } from './types';

const formatDate = dateString => {
  return isValid(dateString) ? format(dateString, 'MMM yyyy') : '';
};

function HistoricalPerformance({
  returnsData,
  portfolioInfo = {
    benchmark: '',
    currentPortfolioName: '',
    recommendedPortfolioName: '',
  },
  showAllPortfolioItems = false,
  showOnlyCurrentPortfolioAndBenchmark = false,
  headerTitle = 'Historical Returns',
  returnPeriod,
  isAllAccountsSelected = true,
}: HistoricalPerformanceProps) {
  const portfolioAndBenchmarkInfo = useMemo(() => {
    const currentPortfolioInfo = {
      key: 'currentPortfolio',
      label: 'Current Portfolio',
      value: !isAllAccountsSelected ? portfolioInfo?.currentPortfolioName : '',
      legendColor: GRAPH_COLORS.currentPortfolio,
      percentage: returnsData?.returns?.portfolioPercentage || 0,
    };
    const recommendedPortfolioInfo = {
      key: 'recommendedPortfolio',
      label: 'Recommended Portfolio',
      value: portfolioInfo?.recommendedPortfolioName ?? '',
      legendColor: GRAPH_COLORS.recommendedPortfolio,
      percentage: returnsData?.returns?.recommendedPercentage || 0,
    };
    const benchmarkInfo = {
      key: 'benchmark',
      label: portfolioInfo?.benchmark ?? '',
      value: 'Relative Security',
      percentage: returnsData?.returns?.benchmarkPercentage || 0,
      legendColor: GRAPH_COLORS.benchmark,
    };

    const basePortfolioInfo = () => {
      if (showAllPortfolioItems) {
        return [currentPortfolioInfo, recommendedPortfolioInfo];
      } else if (showOnlyCurrentPortfolioAndBenchmark) {
        return [currentPortfolioInfo];
      } else return [recommendedPortfolioInfo];
    };

    return [...basePortfolioInfo()].concat(
      returnsData?.returns?.benchmarkPercentage ? benchmarkInfo : [],
    );
  }, [
    portfolioInfo,
    returnsData,
    showAllPortfolioItems,
    showOnlyCurrentPortfolioAndBenchmark,
  ]);

  // mapping each key from returnsData to it's corresponding values for chart component to identify which data is related to portfolio/benchmark
  const dataKeyToPortfolioMapping = useMemo(() => {
    if (showAllPortfolioItems) {
      return {
        valueFirst: 'recommendedPortfolio',
        valueSecond: 'currentPortfolio',
        valueThird: 'benchmark',
      };
    } else if (showOnlyCurrentPortfolioAndBenchmark) {
      return {
        valueFirst: '',
        valueSecond: 'currentPortfolio',
        valueThird: 'benchmark',
      };
    } else
      return {
        valueFirst: 'recommendedPortfolio',
        valueSecond: '',
        valueThird: 'benchmark',
      };
  }, [showAllPortfolioItems, showOnlyCurrentPortfolioAndBenchmark]);

  const returnPeriodInfo = useMemo(() => {
    const chartData = returnsData?.returns?.chartsData ?? [];

    if (!chartData.length) return { returnPeriod: 0, range: [] };

    const chartsData = returnsData?.returns?.chartsData;
    const startDate = chartsData?.[1]?.date;
    const endDate = chartsData?.[chartsData.length - 1]?.date;

    const startDateRange = formatDate(new Date(startDate));
    const endDateRange = formatDate(new Date(endDate));

    const formattedReturnPeriod =
      returnPeriod > 12
        ? `${Math.floor(returnPeriod / 12)} year`
        : `${returnPeriod} month`;

    return {
      returnPeriod: formattedReturnPeriod ?? '0',
      range: [startDateRange, endDateRange],
    } as { returnPeriod: string; range: string[] };
  }, [returnPeriod, returnsData]);

  return (
    <ProposalPage headerTitle={headerTitle}>
      <Text
        style={tw(
          'font-inter font-medium text-sm leading-[1.059rem] text-default mb-2 min-h-6',
        )}
      >
        Simulated Historical Return for $100
      </Text>

      <Text style={tw('font-inter text-xs leading-4 text-neutral600')}>
        Cumulative Returns for the {returnPeriodInfo?.returnPeriod ?? ''} period
        measured from {returnPeriodInfo?.range[0] ?? ''} to{' '}
        {returnPeriodInfo?.range[1] ?? ''}
      </Text>
      <View style={tw('flex flex-row gap-4 mt-3 h-[180px]')}>
        {Array.isArray(portfolioAndBenchmarkInfo) &&
        portfolioAndBenchmarkInfo.length > 0 ? (
          portfolioAndBenchmarkInfo.map(portfolio => {
            const percentage = portfolio?.percentage
              ? +portfolio.percentage
              : 0;

            return (
              <View
                key={portfolio?.key}
                style={tw(
                  'h-full border border-neutral200 rounded p-4 w-[200px]',
                )}
              >
                <View style={tw('h-full flex flex-col justify-between')}>
                  <View>
                    <View
                      style={tw(
                        `h-[1.125rem] w-[1.125rem] mb-1 rounded bg-[${portfolio?.legendColor ?? '#000'}]`,
                      )}
                    />
                    <Text
                      style={tw(
                        'font-inter font-medium text-xs leading-4 text-neutral600 my-2',
                      )}
                    >
                      {portfolio?.label ?? ''}
                    </Text>
                    <Text
                      style={tw(
                        'font-inter font-medium text-[0.625rem] leading-5 text-neutral500',
                      )}
                    >
                      {portfolio?.value ?? ''}
                    </Text>
                  </View>
                  <View style={tw('flex flex-row gap-1 items-center py-4')}>
                    <Svg
                      fill={percentage > 0 ? '#12B76A' : '#FA4D56'}
                      stroke-width="0"
                      viewBox="0 0 24 24"
                      height="24px"
                      width="24px"
                    >
                      <G>
                        <Path d="M12 8l6 6H6z"></Path>
                      </G>
                    </Svg>
                    <Text
                      style={tw(
                        `${percentage > 0 ? 'text-[#12B76A]' : 'text-[#FA4D56]'} font-inter text-[1.2rem] ml-0.5`,
                      )}
                    >
                      {!isNaN(percentage) ? percentage?.toFixed(2) : '--'}
                    </Text>
                  </View>
                </View>
              </View>
            );
          })
        ) : (
          <></>
        )}
      </View>
      <View style={tw('border border-[#DCDCDC] rounded my-2')}>
        <View style={tw('w-full flex flex-row justify-end ')}>
          <Text
            style={tw(
              'font-inter text-[0.5rem] leading-[0.605rem] text-neutral400 p-3 min-h-[2.125rem]',
            )}
          >
            {returnPeriodInfo?.returnPeriod ?? ''} period (
            {returnPeriodInfo?.range?.[0] ?? ''} to{' '}
            {returnPeriodInfo?.range?.[1] ?? ''})
          </Text>
        </View>
        <View style={tw('p-8')}>
          <LineGraph
            data={returnsData?.returns?.chartsData ?? []}
            layoutConfig={{
              height: 250,
              width: 620,
              margin: {
                top: 15,
                right: 50,
                left: 20,
                bottom: 5,
              },
            }}
            chartConfig={{
              graphColors: GRAPH_COLORS,
              customTickColor: '#98A2B3',
            }}
            dataKeyToPortfolioMapping={dataKeyToPortfolioMapping}
          />
        </View>
      </View>
      <View>
        <Text
          style={tw(
            'font-inter italic text-[0.5rem] font-normal leading-5 text-neutral600',
          )}
        >
          Historical Performance with a starting balance of $100 as of the
          requested performance start date. Funds (e.g. Mutual Funds and ETFs)
          are calculated net of fees. Common Stock Equities are calculated as
          Total Return. Portfolio allocations are held constant and rebalanced.
          The relative investment security for comparative performance is
          selected by the Advisor and not an official benchmark or index. The
          performance information shown represents past performance and is not a
          guarantee of future results. Return used to calculate Simulated
          Historical Return of $100 is net of the advisor’s annual advisory fee.
        </Text>
      </View>
      <PageBottomContainer>
        <Disclosure
          text={[
            `The performance information shown represents past performance and is not a guarantee of future results.`,
            `Returns shown are net of the advisor’s annual advisory fee.`,
            `Investing in equity markets involves risk, you could lose a significant portion or all of your original investment, Main Street Advisors does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy.`,
            `Investment returns will fluctuate and are subject to market volatility, so that an investor's shares, when redeemed or sold, may be worth more or less than their original cost. Mutual funds and ETFs may have investment objectives other than matching a particular market index and are subject to risks similar to those of stocks. Because of ongoing market volatility, fund performance may be subject to substantial short-term changes. There is no certainty that any investment or strategy will be profitable or successful in achieving investment objectives. Information obtained from sources believed to be reliable but there is no guarantee of their accuracy.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default HistoricalPerformance;

import { Allocation } from './types';

export const allocations = currentPortfolioAllocationsData => {
  return [
    {
      title: 'Regional Allocation',
      label: 'regionAllocation',
      data: currentPortfolioAllocationsData?.regionAllocation,
    },
    {
      title: 'Sector Allocation',
      label: 'sectorAllocation',
      data: currentPortfolioAllocationsData?.sectorAllocation,
    },
    {
      title: 'Asset Allocation',
      label: 'assetAllocation',
      data: currentPortfolioAllocationsData?.assetAllocation,
    },
  ] as Allocation[];
};

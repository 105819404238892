import { svgToDataUri } from 'app/components/RenderToImage';

export const fetchSvg = async (imageUrl: string) => {
  try {
    const response = await fetch(imageUrl);
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const contentType = response?.headers?.get('content-type') ?? '';

    if (contentType?.includes('image/svg+xml')) {
      const assetData = await response.text();
      const enhancedSvg = await svgToDataUri(assetData ?? '');
      return enhancedSvg ?? '';
    } else {
      return imageUrl;
    }
  } catch (error) {
    console.error('Error fetching asset:', error);
    return '';
  }
};

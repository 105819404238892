import { isParamContainsSage } from 'utils/helpers';

export const filterData = (yearLabel: string, difference: number) => {
  switch (yearLabel) {
    case 'one_month':
      return 1 <= difference || isParamContainsSage();
    case 'three_months':
      return 3 <= difference || isParamContainsSage();
    case 'six_months':
      return 6 <= difference || isParamContainsSage();
    case 'one_year':
      return 12 <= difference || isParamContainsSage();
    case 'three_years':
      return 36 <= difference || isParamContainsSage();
    case 'five_years':
      return 60 <= difference || isParamContainsSage();
    case 'ten_years':
      return 120 <= difference || isParamContainsSage();
    default:
      return true;
  }
};

export const getStartEndDates = returnsData => {
  if (returnsData && Array.isArray(returnsData) && returnsData?.length > 0) {
    const data = returnsData ?? [];
    const lastIndex = data.length > 1 ? data.length - 1 : '';
    const startDate = data[1]?.date || '';
    const endDate = data[lastIndex]?.date || '';
    return { startDate, endDate };
  }
};

export const portfolioTableRow = primaryBenchmark => {
  const baseRows = [
    {
      label: 'Current Portfolio',
      key: 'currentPortfolioPercentage',
    },
    {
      label: 'Recommended Portfolio',
      key: 'recommendationPercentage',
    },
  ];

  const fullRows = [
    ...baseRows,
    ...(primaryBenchmark
      ? [{ label: primaryBenchmark ?? '', key: 'benchmarkPercentage' }]
      : []),
  ];

  return fullRows;
};

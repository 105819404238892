import { fetchSvg } from './fetchSvg';
import { RecommendationDetails } from 'app/pages/ProposalPdf/types';

export const fetchSubModelLogos = async (
  recommendationDetails: RecommendationDetails,
) => {
  const blendedSubModels = recommendationDetails?.subModels ?? [];

  const isBlendedModelsPresent =
    Array.isArray(blendedSubModels) && blendedSubModels?.length > 0;

  try {
    if (isBlendedModelsPresent) {
      const logoPromises = blendedSubModels.map(model =>
        fetchSvg(model?.logo ?? ''),
      );
      const logos: string[] = await Promise.all(logoPromises ?? []);
      return logos?.filter(logo => logo !== '') ?? [];
    }
  } catch (error) {
    console.error('Failed to fetch logos:', error);
    return [];
  }
};

export const getMinWidthForHoldings = (type: string) => {
  switch (type) {
    case 'SYMBOL':
      return '130px';
    case 'NAME':
      return '280px';
    case 'RISK_SCORE':
      return '100px';
    case 'VALUE':
      return '130px';
    case 'WEIGHTS':
      return '130px';
    default:
      return '100px';
  }
};

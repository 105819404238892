import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import PieGraph from '../PieGraph';
import { tw } from 'utils/tailwindSetup';
import { getAllocationData, getChartLabel } from './utils';
import { PIE_CHART_COLORS_FOR_ALLOCATIONS } from 'utils/constants/pieChartColor';
import { CompareAllocationsProps } from './types';

function CompareAllocations({
  compareData,
  pieChartColors = [],
  allocationType = 'ASSET_ALLOCATION',
}: CompareAllocationsProps) {
  const displayCompareData = useMemo(() => {
    if (Array.isArray(compareData) && compareData.length > 0) {
      return compareData?.slice(0, 2);
    } else return [];
  }, [compareData]);

  return (
    <View style={tw('w-full flex flex-row gap-4')}>
      {Array.isArray(displayCompareData) && displayCompareData.length > 0 ? (
        displayCompareData.map((data, index) => {
          const allocationData = data ?? [];
          const statsData = getAllocationData(allocationData ?? []);
          const isAllocationDataAvailable =
            Array.isArray(allocationData) && allocationData.length > 0;

          return (
            <View style={tw('w-full bg-white flex flex-col items-center')}>
              {isAllocationDataAvailable ? (
                <>
                  <View style={tw('p-4 mb-8')}>
                    <PieGraph
                      data={allocationData ?? []}
                      height={170}
                      width={170}
                      innerRadius={65}
                      outerRadius={80}
                      strokeWidth={3.5}
                      blendStroke={true}
                      label={getChartLabel(index) ?? ''}
                      labelSize={14}
                      labelFontColor="#101828"
                      labelFontWeight={500}
                      customLabelSpacing={25}
                      pieChartColor={
                        Array.isArray(pieChartColors) &&
                        pieChartColors?.length > 0
                          ? pieChartColors
                          : PIE_CHART_COLORS_FOR_ALLOCATIONS
                      }
                    />
                  </View>
                  <View style={tw('w-full')}>
                    <View
                      style={tw(
                        'w-full flex flex-row bg-neutral25 p-4 border border-neutral200',
                      )}
                    >
                      <Text
                        style={tw(
                          'flex-1 font-inter font-medium text-sm leading-4 text-default',
                        )}
                      >
                        {allocationType === 'ASSET_ALLOCATION'
                          ? 'Asset Class'
                          : 'Region'}
                      </Text>
                      <Text
                        style={tw(
                          'flex-1 font-inter font-medium text-sm leading-4 text-default',
                        )}
                      >
                        Allocation
                      </Text>
                    </View>
                    <View
                      style={tw(
                        'w-full flex flex-wrap flex-row border border-neutral200',
                      )}
                    >
                      {Array.isArray(statsData) &&
                        statsData.length > 0 &&
                        statsData.map(item => (
                          <>
                            <Text
                              style={tw(
                                'w-1/2 bg-white border-t-0 p-4 font-inter font-medium text-xs leading-4 text-default',
                              )}
                            >
                              {item?.label ?? ''}
                            </Text>
                            <Text
                              style={tw(
                                'w-1/2 bg-white border-t-0 py-4 font-inter font-medium text-xs leading-4 text-default',
                              )}
                            >
                              {item?.data?.toFixed(2) ?? 0}%
                            </Text>
                          </>
                        ))}
                    </View>
                  </View>
                </>
              ) : (
                <View
                  style={tw(
                    'h-[90%] flex flex-row items-center justify-center',
                  )}
                >
                  <Text
                    style={tw(
                      'font-inter font-medium text-base leading-normal text-neutral600',
                    )}
                  >
                    No data available
                  </Text>
                </View>
              )}
            </View>
          );
        })
      ) : (
        <></>
      )}
    </View>
  );
}

export default CompareAllocations;

import { format, isValid } from 'date-fns';

export const xAxisDomain = data => {
  if (Array.isArray(data) && data.length > 0) {
    const startingDate = data[1]?.date ?? 0;
    const endingDate = data[data.length - 1]?.date ?? 0;
    return [startingDate, endingDate];
  }
  return undefined;
};

export const xAxisTicksFormatter = val => {
  if (val) {
    const date = new Date(val);
    if (isValid(date)) {
      return format(date, 'MM/dd/yyyy') ?? '';
    }
  }
  return '';
};

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { AdvisorFeesProps } from './types';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';

function AdvisorFeesDisclosure({
  advisorFees,
  advisorFeesDisclosure,
  headerTitle = 'Advisor Fee Disclosure',
}: AdvisorFeesProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View>
        <Text
          style={tw('font-medium text-xs text-default leading-8 font-inter')}
        >
          Standard Maximum Fee across all Portfolios
        </Text>
        <Text
          style={tw(
            'font-normal text-[0.625rem] text-neutral600 leading-6 font-inter',
          )}
        >
          The fee represents the maximum standard fee percent that could be
          applied to the client for advisory services. Within this document,
          this fee is included in the calculation of Historical Performance
          charts, Possible Gains & Losses, Projected Returns, and other
          Marketing Materials.
        </Text>
        <View
          style={tw(
            'w-full flex flex-row justify-center my-4 p-6 border border-neutral200',
          )}
        >
          <Text
            style={tw('font-bold text-5xl text-[#457F8F] leading-4 font-inter')}
          >
            {advisorFees ?? 0}%
          </Text>
        </View>
        {advisorFeesDisclosure ? (
          <>
            <Text
              style={tw(
                'font-medium text-xs text-default leading-8 font-inter',
              )}
            >
              Advisor Fee Full Disclosure
            </Text>
            <Text
              style={tw(
                'font-normal text-[0.625rem] text-neutral600 leading-6 font-inter',
              )}
            >
              {advisorFeesDisclosure ?? ''}
            </Text>
          </>
        ) : (
          <></>
        )}
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default AdvisorFeesDisclosure;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import RiskBand from 'app/components/RiskBand';
import { tw } from 'utils/tailwindSetup';
import { CompareRiskBandProps } from './types';

const baseBoldTextStyle = tw(
  'font-inter text-xs font-semibold leading-4 text-default',
);

function CompareRiskBand({
  currentPortfolioData,
  recommendedPortfolioData,
}: CompareRiskBandProps) {
  if (!currentPortfolioData || !recommendedPortfolioData) return <></>;

  return (
    <>
      {recommendedPortfolioData &&
      recommendedPortfolioData?.riskDetails &&
      recommendedPortfolioData?.riskDetails?.riskCapacity !== 0 ? (
        <View
          style={tw(
            'min-h-12.5rem w-full border border-neutral200 rounded flex flex-col items-center justify-center p-8 pb-0 mt-3',
          )}
        >
          <Text
            style={tw('font-inter text-xs leading-4 text-default text-center')}
          >
            Your portfolio risk score is now{' '}
            {currentPortfolioData?.riskScore <
            recommendedPortfolioData?.riskScore
              ? 'more risky increasing '
              : 'less risky decreasing '}
            from{' '}
            <Text style={baseBoldTextStyle}>
              {currentPortfolioData?.riskScore ?? 0}{' '}
            </Text>
            to{' '}
            <Text style={baseBoldTextStyle}>
              {recommendedPortfolioData?.riskScore ?? 0}
            </Text>
          </Text>
          <View style={tw('w-[90%] min-h-[15rem] flex flex-row items-center')}>
            <RiskBand
              riskData={
                recommendedPortfolioData?.riskDetails ?? {
                  riskCapacity: 0,
                  riskPreference: 0,
                }
              }
              riskScores={[
                {
                  value: recommendedPortfolioData?.riskScore ?? 0,
                },
                {
                  color: '#457F8F',
                  value: currentPortfolioData?.riskScore ?? 0,
                },
              ]}
            />
          </View>
        </View>
      ) : (
        <></>
      )}
    </>
  );
}

export default CompareRiskBand;

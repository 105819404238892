export const deriveRiskBandText = (riskBand, riskScore) => {
  const capacity = riskBand?.riskCapacity ?? 0;
  const preference = riskBand?.riskPreference ?? 0;
  const score = riskScore ?? 0;

  const capacityLessThanPreference = capacity < preference;
  const inBand =
    (score >= capacity && score <= preference) ||
    (score <= capacity && score >= preference);

  if (capacityLessThanPreference && !inBand) {
    // C<P - Out of Band
    return `Your Risk Preference (${preference}) is greater than than your Risk Capacity (${capacity}), meaning you are willing to take on more risk than your ability to. Your recommened portfolio has a risk score of, ${score}, which falls outside of your Risk Band. A Portfolio Risk Score of ${score} means there is a 1% chance that your portfolio can lose ${score}% or more of its value in the next year. Since your preference is higher than your capacity, an ideal portfolio falls closer to your capacity level.`;
  } else if (capacityLessThanPreference && inBand) {
    // C<P - In Band
    return `Your Risk Preference (${preference}) is greater than than your Risk Capacity (${capacity}), meaning you are willing to take on more risk than your ability to. Your recommened portfolio has a risk score of ${score}, which falls inside your Risk Band! A Portfolio Risk Score of ${score} means there is a 1% chance that your portfolio can lose ${score}% or more of its value in the next year. Since your preference is higher than your capacity, you are likely able to withstand this potential loss.`;
  } else if (!capacityLessThanPreference && !inBand) {
    // C>P - Out of Band
    return `Your Risk Preference (${preference}) is less than than your Risk Capacity (${capacity}), meaning your ability to take on more risk is higher than your willingness. Your recommened portfolio has a risk score of ${score}, which falls outside of your Risk Band. A Portfolio Risk Score of ${score} means there is a 1% chance that your portfolio can lose ${score}% or more of its value in the next year. Since your capacity is higher than your preference, the ideal portfolio will fall closer to your capacity.`;
  } else if (!capacityLessThanPreference && inBand) {
    // C>P - In Band
    return `Your Risk Preference (${preference}) is less than than your Risk Capacity (${capacity}), meaning your ability to take on more risk is higher than your willingness. Your recommened portfolio has a risk score of ${score}, which is inside your Risk Band! A Portfolio Risk Score of ${score} means there is a 1% chance that your portfolio can lose ${score}% or more of its value in the next year. Since your capacity is higher than your preference, you are likely able to withstand this potential loss.`;
  } else {
    return '';
  }
};
export const getMinWidthForHoldings = (type: string) => {
  switch (type) {
    case 'SYMBOL':
      return '130px';
    case 'NAME':
      return '280px';
    case 'RISK_SCORE':
      return '100px';
    case 'VALUE':
      return '130px';
    case 'WEIGHTS':
      return '130px';
    default:
      return '100px';
  }
};

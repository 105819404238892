import React from 'react';
import RiskScore from 'app/pages/RecommendedPortfolio/RiskScore';
import ExpenseRatio from 'app/pages/ExpenseRatio';
import CurrentPortfolioHoldings from '../Holdings';
import CurrentPortfolioAllocation from '../Allocations';
import { CurrentPortfolioForWealthProps } from './types';

function CurrentPortfolioForWealth({
  currentPortfolioAllocationsData,
  currentPortfolioHoldings,
  headerTitle = 'Current Portfolio',
  currentPortfolioData,
  recommendedPortfolioData,
  feesValue,
  recommendedModelName,
  isTemplateCurrentPortfolio = false,
}: CurrentPortfolioForWealthProps) {
  return (
    <>
      <CurrentPortfolioHoldings
        headerTitle={headerTitle}
        currentPortfolioHoldings={currentPortfolioHoldings}
      />
      <CurrentPortfolioAllocation
        headerTitle={headerTitle}
        currentPortfolioAllocationsData={currentPortfolioAllocationsData}
      />
      {isTemplateCurrentPortfolio ? (
        <>
          <RiskScore
            currentPortfolioData={currentPortfolioData}
            recommendedPortfolioData={recommendedPortfolioData}
            portfolioType="CURRENT"
          />
          <ExpenseRatio
            type="currentPortfolio"
            pageDefaultHeader={headerTitle}
            feesValue={{
              currentPortfolio: feesValue,
            }}
            displayAllPortfolio={false}
            recommendedModelName={recommendedModelName ?? ''}
          />
        </>
      ) : (
        <></>
      )}
    </>
  );
}

export default CurrentPortfolioForWealth;

import { PAGE_CONTENT_LIST } from './pageContentList';

export const contentList = [
  {
    title: 'General',
    elements: [
      {
        title: 'Cover',
        type: PAGE_CONTENT_LIST.COVER,
      },
      {
        title: 'Table of Contents',
        type: PAGE_CONTENT_LIST.TABLE_OF_CONTENTS,
      },
      {
        title: 'IPS Introduction',
        type: PAGE_CONTENT_LIST.IPS,
      },
    ],
  },
  {
    title: 'Driver Results',
    elements: [
      {
        title: 'Risk Band',
        type: PAGE_CONTENT_LIST.RISK_BAND,
      },
      {
        title: 'Assessment Inputs',
        type: PAGE_CONTENT_LIST.ASSESSMENT_INPUTS,
      },
    ],
  },
  {
    title: 'Recommendation',
    elements: [
      {
        title: 'Snapshot',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_SNAPSHOT,
      },
      {
        title: 'Holdings',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_HOLDINGS,
      },
      {
        title: 'Risk',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_RISK_SCORE,
      },
      {
        title: 'Expense Ratio',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_EXPENSE_RATIO,
      },
      {
        title: 'Allocations',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS,
      },
      {
        title: 'Themes',
        type: PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_THEMES,
      },
    ],
  },
  {
    title: 'Current Portfolio',
    elements: [
      {
        title: 'Current Portfolio',
        type: PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_HOLDINGS,
      },
      {
        title: '',
        type: PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_ALLOCATION_CHARTS,
      },
    ],
  },
  {
    title: 'Compare',
    elements: [
      {
        title: 'Compare - Risk Scores',
        type: PAGE_CONTENT_LIST.COMPARE_RISK,
      },
      {
        title: 'Compare - Expense Ratio',
        type: PAGE_CONTENT_LIST.COMPARE_EXPENSE_RATIO,
      },
      {
        title: 'Compare - Holdings',
        type: PAGE_CONTENT_LIST.COMPARE_HOLDINGS,
      },
      {
        title: 'Compare - Asset Allocation',
        type: PAGE_CONTENT_LIST.ASSET_ALLOCATION,
      },
      {
        title: 'Compare - Region Allocation',
        type: PAGE_CONTENT_LIST.REGION_ALLOCATION,
      },
      {
        title: 'Compare - Historical Performance',
        type: PAGE_CONTENT_LIST.HISTORICAL_PERFORMANCE,
      },
      {
        title: 'Compare - Return Simulation',
        type: PAGE_CONTENT_LIST.RETURN_SIMULATION,
      },
      {
        title: 'Compare - Historical Return Simulation',
        type: PAGE_CONTENT_LIST.COMPARE_HISTORICAL_RETURN_SIMULATION,
      },
      {
        title: 'Compare - Risk & Fees',
        type: PAGE_CONTENT_LIST.COMPARE_RISK_AND_FEES,
      },
      {
        title: 'Compare - Sectors',
        type: PAGE_CONTENT_LIST.SECTOR_ALLOCATION,
      },
    ],
  },
  {
    title: 'Analytics',
    elements: [
      {
        title: 'Historical Returns',
        type: PAGE_CONTENT_LIST.HISTORICAL_RETURN,
      },
      {
        title: 'Potential Return',
        type: PAGE_CONTENT_LIST.POTENTIAL_RETURN,
      },
      {
        title: 'Risk Statistics',
        type: PAGE_CONTENT_LIST.POTENTIAL_RISK,
      },
    ],
  },
  {
    title: 'Disclosures',
    elements: [
      {
        title: 'Duties and Responsibilities',
        type: PAGE_CONTENT_LIST.DUTIES_AND_RESPONSIBILITIES,
      },
      {
        title: 'Disclosures',
        type: PAGE_CONTENT_LIST.DISCLOSURES,
      },
      {
        title: 'Advisor Fee Disclosure',
        type: PAGE_CONTENT_LIST.ADVISOR_FEE_DISCLOSURE,
      },
      {
        title: 'TIFIN Wealth Disclosure',
        type: PAGE_CONTENT_LIST.WEALTH_DISCLOSURES,
      },
      {
        title: 'Risks and Disclosures',
        type: PAGE_CONTENT_LIST.RISK_DISCLOSURES,
      },
      {
        title: 'Appendix',
        type: PAGE_CONTENT_LIST.APPENDIX,
      },

      {
        title: 'Appendix CMA',
        type: PAGE_CONTENT_LIST.APPENDIX_CMA,
      },
    ],
  },
];

import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import CompareHoldingsTable from './Table';

import currentPortfolioIcon from 'assets/currentPortfolioCompareIcon.png';
import recommendedPortfolioIcon from 'assets/recommendedPortfolioIcon.png';
import { tw } from 'utils/tailwindSetup';
import { CompareHoldingsProps } from './types';

function CompareHoldings({
  compareHoldingsData: {
    currentPortfolioHoldings,
    modelName,
    recommendedPortfolioHoldings,
  },
  accountName,
  headerTitle = 'Compare - Holdings',
}: CompareHoldingsProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View>
        <Text
          style={tw(
            'mb-[0.625rem] font-inter text-default text-lg font-semibold',
          )}
          fixed
        >
          Holding
        </Text>
        <View style={tw('w-full mb-[0.9375rem]')} fixed>
          <View style={tw('flex flex-row gap-3 mb-4')}>
            <View style={tw('w-[22.5rem]')}>
              <View
                style={tw(
                  'flex flex-row items-center p-3 border border-darkGreen rounded-[0.3125rem] min-h-[4.375rem]',
                )}
              >
                <Image src={currentPortfolioIcon} style={tw('h-3.5 w-3.5')} />
                <Text
                  style={tw(
                    'text-xs font-inter font-bold text-darkGreen ml-[0.9375rem] leading-[1.125rem]',
                  )}
                >
                  {accountName}
                </Text>
              </View>
            </View>
            <View style={tw('w-[22.5rem]')}>
              <View
                style={tw(
                  'flex flex-row items-center p-3 border border-darkGreen rounded-[0.3125rem] min-h-[4.375rem]',
                )}
              >
                <Image
                  src={recommendedPortfolioIcon}
                  style={tw('h-3.5 w-3.5')}
                />
                <Text
                  style={tw(
                    'text-xs font-inter font-bold text-darkGreen ml-[0.9375rem] leading-[1.125rem]',
                  )}
                >
                  {modelName || 'Recommended Portfolio'}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={tw('flex flex-row gap-2.5')}>
          <CompareHoldingsTable
            holdingsData={currentPortfolioHoldings}
            showBorder={false}
            portfolioType="CURRENT_PORTFOLIO"
          />
          <CompareHoldingsTable
            holdingsData={recommendedPortfolioHoldings}
            portfolioType="RECOMMENDED_PORTFOLIO"
          />
        </View>
      </View>
      <PageBottomContainer />
    </ProposalPage>
  );
}

export default CompareHoldings;

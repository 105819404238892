import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import ProjectedGrowth from 'app/components/ProjectedGrowth';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';

import currentPortfolioIcon from 'assets/currentPortfolioIcon.png';
import recommendedPortfolioIcon from 'assets/recommendedPortfolioIcon.png';
import { tw } from 'utils/tailwindSetup';
import {
  formatDigitUptoDecimalPlaces,
  isParamContainsSage,
} from 'utils/helpers';
import { CompareReturnSimulationProps } from './types';

function CompareReturnSimulation({
  accountName = '',
  currentPortfolioData,
  recommendedPortfolioData,
  headerTitle = 'Compare - Return Simulation',
  advisorFee = 0,
  advisorFirmName = '',
}: CompareReturnSimulationProps) {
  const isSageDocument = isParamContainsSage();

  return (
    <ProposalPage headerTitle={headerTitle}>
      <Text
        style={tw('font-inter font-medium text-sm leading-4 text-default pb-2')}
      >
        10-Year Potential Return Forecasts
      </Text>
      <View style={tw('w-full flex flex-row justify-between pt-4')}>
        <View style={tw('w-1/2')}>
          <Text
            style={tw(
              'font-inter text-sm leading-[1.059rem] text-neutral400 mb-4 min-h-4',
            )}
          >
            Current Portfolio
          </Text>
        </View>
        <View style={tw('w-1/2 flex justify-self-start')}>
          <Text
            style={tw(
              'font-inter text-sm leading-[1.059rem] text-neutral400 mb-4 ml-4 min-h-4',
            )}
          >
            Recommended Portfolio
          </Text>
        </View>
      </View>
      <View style={tw('w-full flex flex-row justify-between gap-4')}>
        {/* current portfolio */}
        <View style={tw('flex-1 flex flex-row gap-3')}>
          {Array.isArray(currentPortfolioData?.projectedGrowth) &&
            currentPortfolioData?.projectedGrowth.length > 0 && (
              <View
                style={tw(
                  'w-full p-4 pt-0 border border-[#DCDCDC] rounded h-[31.25rem]',
                )}
              >
                <View style={tw('mb-6')}>
                  <View
                    style={tw(
                      'flex flex-row items-center w-full pb-2 p-4 pl-0',
                    )}
                  >
                    <Image src={currentPortfolioIcon} style={tw('w-4 h-4')} />
                    <Text
                      style={tw(
                        'font-inter font-medium text-[0.5rem] leading-4 text-black ml-1',
                      )}
                    >
                      {accountName || 'Current Portfolio'}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={tw(
                        'font-inter font-medium text-[0.625rem] text-neutral400 leading-5 py-2',
                      )}
                    >
                      In 10-years, your portfolio balance of $
                      {formatDigitUptoDecimalPlaces(
                        currentPortfolioData?.marketValue ?? 0,
                      ) ?? 'N/A'}{' '}
                      is projected to be:
                    </Text>
                  </View>
                </View>
                <ProjectedGrowth
                  data={currentPortfolioData?.projectedGrowth ?? []}
                  overrideXAxisInterval="preserveStartEnd"
                />
              </View>
            )}
        </View>

        {/* recommended portfolio */}
        <View style={tw('flex-1 flex flex-row gap-3')}>
          {Array.isArray(recommendedPortfolioData?.projectedGrowth) &&
            recommendedPortfolioData?.projectedGrowth.length > 0 && (
              <View
                style={tw(
                  'w-full p-4 pt-0 border border-[#DCDCDC] rounded h-[31.25rem]',
                )}
              >
                <View style={tw('mb-6')}>
                  <View
                    style={tw(
                      'flex flex-row items-center w-full pb-2 p-4 pl-0',
                    )}
                  >
                    <Image
                      src={recommendedPortfolioIcon}
                      style={tw('w-4 h-4')}
                    />
                    <Text
                      style={tw(
                        'font-inter font-medium text-[0.5rem] leading-4 text-black ml-1',
                      )}
                    >
                      {recommendedPortfolioData?.baseModel?.modelName ||
                        'Recommended Portfolio'}
                    </Text>
                  </View>
                  <View>
                    <Text
                      style={tw(
                        'font-inter font-medium text-[0.625rem] text-neutral400 leading-5 py-2',
                      )}
                    >
                      In 10-years, your portfolio balance of $
                      {formatDigitUptoDecimalPlaces(
                        recommendedPortfolioData?.marketValue ?? 0,
                      ) ?? 'N/A'}{' '}
                      is projected to be:
                    </Text>
                  </View>
                </View>

                <ProjectedGrowth
                  data={recommendedPortfolioData?.projectedGrowth ?? []}
                  overrideXAxisInterval="preserveStartEnd"
                />
              </View>
            )}
        </View>
      </View>
      <Text
        style={tw(
          'w-full font-inter italic text-[0.5rem] leading-6 text-neutral600 py-4',
        )}
      >
        The figures shown reflect the deduction of the advisor's annual advisory
        fee, but may not include commissions, sales charges, custodial fees or
        platform fees, which, if included would result in lower values.
      </Text>
      <PageBottomContainer>
        <Disclosure
          text={[
            `${isSageDocument ? 'SAGE' : 'TIFIN'} aggregates Capital Market Assumptions (CMAs) reported by the top asset management institutions and uses Geometric Brownian Motion (GBM) to project Current and Recommended portfolio balances for 10 years. Geometric Brownian motion is used to describe random movements of financial assets over time where the size of the variations is proportional to the current value of the asset. GBM is the industry standard model used in finance to forecast security returns. The benefits of GBM over Monte Carlo (making standard industry assumptions such as normality and static volatility) is speed (runs much faster) and will return the same results every time due to forecasting every point on the distribution versus making a random distribution. Potential drawbacks of GBM (and Monte Carlo) is the assumption of constant volatility and normal distribution; however, over long enough time periods these potential drawbacks become mitigated.`,
            `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} aggregates Capital Market Assumptions (CMAs) from various large third-party asset managers and uses proprietary techniques to forecast security and portfolio-specific returns and volatility. For additional information on CMAs please see the Appendix.`,
            `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} calculates the forward expected returns and volatility for the Current and Recommended Portfolios.`,
            `Using geometric Brownian motion, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} projects the Current and Recommended portfolios’ balances for 10 years. The “Mean band” represents the expected return (50th percentile)of the respected portfolio (Current or Recommended); the “Lower band” represents the bottom 15th percentile of possible portfolio balances; and the “Upper band” represents the top 85th percentile of possible portfolio balances. This results in ~70% of possible portfolio balances falling with the “Upper” and “Lower” bands; given the forecasted portfolio’s return and volatility.`,
            `The annual investment advisory fee used in this report is ${advisorFee ?? 0}%.`,
            `Important: The projections or the information generated by this simulation regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results. Results may vary with each use and over time. Other investments not considered might have characteristics similar or superior to those analyzed in this report.`,
            `${advisorFirmName || 'The Advisory Firm'} does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Actual trading results may be materially lower than those illustrated.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default CompareReturnSimulation;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import RiskBand from 'app/components/RiskBand';
import RiskScoreContent from './RiskScoreContent';
import Disclosure from 'app/components/Disclosure';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { tw } from 'utils/tailwindSetup';
import { displayRiskScoreEditDetails } from './utils';
import { isParamContainsSage } from 'utils/helpers';
import { RiskBandProps } from './types';

function RiskBandPage({
  headerTitle = 'Risk Band',
  riskDetails,
}: RiskBandProps) {
  const isSageDocument = isParamContainsSage();
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View>
        <Text
          style={tw(
            'font-inter text-sm leading-[1.059rem] text-default pb-2.5 font-medium',
          )}
        >
          Know your Risk Band
        </Text>
        <Text style={tw('font-inter text-xs leading-4 text-neutral600 my-2')}>
          Your Risk Band is the area between your{' '}
          <Text
            style={tw(
              'font-inter text-xs leading-4 text-neutral600 mb-2 font-bold',
            )}
          >
            Risk Preference
          </Text>{' '}
          and{' '}
          <Text
            style={tw(
              'font-inter text-xs leading-4 text-neutral600 mb-2 font-bold',
            )}
          >
            Risk Capacity
          </Text>{' '}
          scores.
        </Text>
        <Text style={tw('font-inter text-xs leading-4 text-neutral600')}>
          Your recommended portfolio should fall within your Risk Band.
        </Text>
      </View>
      <View
        style={tw(
          'min-h-[14.375rem] my-6 flex flex-row justify-center items-center border border-neutral200 rounded px-10',
        )}
      >
        <View style={tw('w-4/5')}>
          <RiskBand
            riskData={{
              riskCapacity: riskDetails?.riskCapacity ?? 0,
              riskPreference: riskDetails?.riskPreference ?? 0,
            }}
          />
        </View>
      </View>
      <View style={tw('flex flex-row gap-2 justify-between h-72')}>
        <RiskScoreContent
          score={riskDetails?.riskPreference ?? 0}
          riskType="RISK_PREFERENCE"
        />
        <RiskScoreContent
          score={riskDetails?.riskCapacity ?? 0}
          riskType="RISK_CAPACITY"
        />
      </View>

      <Text style={tw('w-full text-neutral400 text-[0.625rem] leading-4 mt-5')}>
        {displayRiskScoreEditDetails(riskDetails)}
      </Text>
      <PageBottomContainer additionalStyles={tw('p-2')}>
        <Disclosure
          text={[
            `${isSageDocument ? `Sage’s` : `TIFIN Wealth’s`} comprehensive approach looks at multiple risk factors to create your personalized risk band. We score both your Risk Preference (the risk you are willing to take) and Risk Capacity (your ability to take risk based on the facts of your financial life). While these scores can range from 1-99, we see most user scores fall within the Conservative to Growth range of 6-55. Ideally, your investment strategy (Portfolio Risk Score) would fall between your Risk Capacity and Risk Preference scores. This represents a hypothetical target that you would seek to keep your investments within. There is no guarantee that any investments would perform within this target.`,
            `Please see Appendix: ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} Risk Scoring Methodology. Risk Score assigned does not guarantee any level of performance or against loss of principal.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default RiskBandPage;

import React from 'react';
import ReactPDFChart from 'react-pdf-charts';
import {
  Bar,
  BarChart,
  CartesianGrid,
  LabelList,
  ReferenceLine,
  YAxis,
} from 'recharts';
import LabelContent from './LabelContent';
import {
  BENCHMARK_KEY,
  CURRENT_PORTFOLIO_KEY,
  RECOMMENDED_PORTFOLIO_KEY,
} from './constants';
import { displayPortfolioData } from 'utils/helpers';
import { RiskStatisticsGraphProps } from './types';

function RiskStatisticsGraph({
  data,
  isTemplateCurrentPortfolio = false,
}: RiskStatisticsGraphProps) {
  if (!data || !Array.isArray(data) || !data.length) return <></>;

  return (
    <ReactPDFChart>
      <BarChart
        data={data ?? []}
        barGap={10}
        width={600}
        height={150}
        margin={{
          top: 20,
          right: 25,
          left: 16,
          bottom: 25,
        }}
      >
        <CartesianGrid
          stroke="#98A2B3"
          vertical={false}
          strokeDasharray="3 3"
        />

        <ReferenceLine
          y="0"
          stroke="#98A2B3"
          label={{
            fill: '#98A2B3',
            width: '60',
            fontSize: '12',
          }}
        />
        <YAxis
          fontSize={8}
          tickCount={4}
          axisLine={false}
          tick={{ fill: '#98A2B3' }}
          tickLine={false}
          tickFormatter={value => `${+value?.toFixed(2)}%`}
        />
        {displayPortfolioData(data, CURRENT_PORTFOLIO_KEY) && (
          <Bar
            isAnimationActive={false}
            barSize={30}
            dataKey={CURRENT_PORTFOLIO_KEY}
            fill="#7B73E4"
          >
            <LabelList
              dataKey={CURRENT_PORTFOLIO_KEY}
              fontSize="10px"
              fill="black"
              content={<LabelContent />}
            />
          </Bar>
        )}
        {displayPortfolioData(data, RECOMMENDED_PORTFOLIO_KEY) && (
          <Bar
            isAnimationActive={false}
            barSize={30}
            dataKey={RECOMMENDED_PORTFOLIO_KEY}
            fill={isTemplateCurrentPortfolio ? '#00B186' : '#FFA32C'}
          >
            <LabelList
              dataKey={RECOMMENDED_PORTFOLIO_KEY}
              fontSize="10px"
              fill="black"
              content={<LabelContent />}
            />
          </Bar>
        )}
        {displayPortfolioData(data, BENCHMARK_KEY) && (
          <Bar
            isAnimationActive={false}
            barSize={30}
            dataKey={BENCHMARK_KEY}
            fill="#00B186"
          >
            <LabelList
              dataKey={BENCHMARK_KEY}
              fontSize="10px"
              fill="black"
              content={<LabelContent />}
            />
          </Bar>
        )}
      </BarChart>
    </ReactPDFChart>
  );
}

export default RiskStatisticsGraph;

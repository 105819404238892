import React from 'react';
import { SpinnerCircular } from 'spinners-react';

function Loader() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <SpinnerCircular
        color="#101828"
        secondaryColor="#E4E7EC"
        size={100}
        thickness={150}
        speed={180}
      />
    </div>
  );
}

export default Loader;

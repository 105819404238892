import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { pieChartColor } from 'utils/constants/pieChartColor';
import { tw } from 'utils/tailwindSetup';
import { AssetAllocationLegendsProps } from './types';

function AssetAllocationLegends({
  allocationType,
  data,
  applyMinWidthStyle = false,
}: AssetAllocationLegendsProps) {
  return (
    <View style={tw('w-full')}>
      {Array.isArray(data?.[allocationType]) &&
        data?.[allocationType]
          .sort((a, b) => b.data - a.data)
          .map((el, index) => {
            const labelColor = pieChartColor(allocationType)?.find(
              item => item.label === el?.label && item,
            );

            return (
              <View
                style={tw(
                  'flex flex-row justify-between text-default py-[0.3125rem]',
                )}
                key={index}
              >
                <View style={tw('flex flex-row items-center')}>
                  <View
                    style={tw(
                      `h-2.5 w-2.5 bg-[${labelColor?.color ?? '#000'}] rounded-sm`,
                    )}
                  />
                  <Text
                    style={tw(
                      `${applyMinWidthStyle ? 'min-w-[7.5rem] px-2' : 'ml-2'} text-neutral500 text-[0.5625rem]`,
                    )}
                  >
                    {el?.label ?? ''}
                  </Text>
                </View>
                <Text
                  style={tw(
                    `${applyMinWidthStyle ? 'text-default' : 'text-neutral500'} text-[0.5625rem] font-medium`,
                  )}
                >
                  {el?.data?.toFixed(2) ?? '-'}%
                </Text>
              </View>
            );
          })}
    </View>
  );
}

export default AssetAllocationLegends;

import React, { useContext } from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { dutiesAndResponsibilitiesProps } from './types';
import { PdfViewerContext } from 'app/context';

function DutiesAndResponsibilities({
  headerTitle = 'Duties and Responsibilities',
  dutiesAndResponsibilities,
}: dutiesAndResponsibilitiesProps) {
  const { advisorDetails, clientDetails } = useContext(PdfViewerContext);
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('mb-[12.5rem]')}>
        <Text
          style={tw('text-xs text-[#292929] font-inter my-2.5 text-justify')}
        >
          {dutiesAndResponsibilities ?? ''}
        </Text>
      </View>
      <View style={tw('mb-20')}>
        <Text style={tw('font-bold leading-4 text-sm text-neutral400 mb-6')}>
          Prepared by:
        </Text>
        <Text style={tw('font-bold leading-4 text-sm text-default')}>
          {advisorDetails?.name ?? ''}
        </Text>
        <Text style={tw('leading-4 text-sm text-default')}>
          {advisorDetails?.firmName ?? ''}
        </Text>
      </View>
      <View style={tw('mb-20')}>
        <Text style={tw('font-medium leading-4 text-sm text-neutral400 mb-6')}>
          Prepared for:
        </Text>
        <Text style={tw('leading-4 text-sm text-default')}>
          {clientDetails.name ?? ''}
        </Text>
      </View>
      <View style={tw('flex flex-row justify-between items-center')}>
        <Text
          style={tw(
            'flex-[0.4_1_0%] pt-2.5 pb-[4.6875rem] border-t border-t-[#292929]',
          )}
        >
          <Text
            style={tw(
              'font-inter text-[#292929] text-justify text-xs mb-[1.875rem]',
            )}
          >
            Signature
          </Text>
        </Text>
        <Text
          style={tw(
            'flex-[0.4_1_0%] pt-2.5 pb-[4.6875rem] border-t border-t-[#292929]',
          )}
        >
          <Text
            style={tw(
              'font-inter text-[#292929] text-justify text-xs mb-[1.875rem]',
            )}
          >
            Date
          </Text>
        </Text>
      </View>
      <View style={tw('flex flex-row justify-between items-center')}>
        <Text
          style={tw(
            'flex-[0.4_1_0%] pt-2.5 pb-[4.6875rem] border-t border-t-[#292929]',
          )}
        >
          <Text
            style={tw(
              'font-inter text-[#292929] text-justify text-xs mb-[1.875rem]',
            )}
          >
            Signature
          </Text>
        </Text>
        <Text
          style={tw(
            'flex-[0.4_1_0%] pt-2.5 pb-[4.6875rem] border-t border-t-[#292929]',
          )}
        >
          <Text
            style={tw(
              'font-inter text-[#292929] text-justify text-xs mb-[1.875rem]',
            )}
          >
            Date
          </Text>
        </Text>
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default DutiesAndResponsibilities;

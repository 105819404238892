import React from 'react';
import ProposalPage from 'app/components/ProposalPage';
import HistoricalReturnSimulationTable from 'app/components/HistoricalReturnSimulationTable';
import PageBottomContainer from 'app/components/PageBottomContainer';

import { HistoricalReturnSimulationProps } from './types';

function HistoricalReturnSimulation({
  tableData,
  difference,
  headerTitle = 'Compare - Historical Return Simulation',
  benchmark,
}: HistoricalReturnSimulationProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <HistoricalReturnSimulationTable
        tableData={tableData ?? []}
        difference={difference ?? 0}
        benchmarkName={benchmark ?? ''}
      />
      <PageBottomContainer />
    </ProposalPage>
  );
}

export default HistoricalReturnSimulation;

import React from 'react';
import { View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import PieGraph from 'app/components/PieGraph';
import AssetAllocationLegends from 'app/components/AssetAllocationLegend';
import { pieChartColor } from 'utils/constants/pieChartColor';
import { tw } from 'utils/tailwindSetup';
import { allocations } from './utils';
import { Allocation, AllocationChartProps } from './types';

function RecommendationAllocation({
  recommendationData,
  selectedTemplate = 'RECOMMENDED_PORTFOLIO',
  isSnapshotView = false,
  headerTitle = 'Recommended Portfolio',
}: AllocationChartProps) {
  const portfolioType =
    selectedTemplate === 'CURRENT_PORTFOLIO'
      ? 'Current Portfolio'
      : isSnapshotView
        ? 'Recommendation Asset Class and Sector Allocations'
        : 'Recommended Portfolio';

  let allocationsToDisplay: Allocation[] = allocations(recommendationData);
  if (isSnapshotView) {
    // only show asset and sector
    allocationsToDisplay = allocationsToDisplay.filter(
      item => item.inludeInSnapshot,
    );
    // move Asset Allocation to the front of the array
    allocationsToDisplay.splice(0, 0, allocationsToDisplay.splice(1, 1)[0]);
  }

  const hidePage = allocationsToDisplay.every(
    allocation => !allocation.data || allocation.data.length === 0,
  );

  if (hidePage) <></>;

  return (
    <ProposalPage headerTitle={isSnapshotView ? portfolioType : headerTitle}>
      {Array.isArray(allocationsToDisplay) &&
        allocationsToDisplay.length > 0 &&
        allocationsToDisplay.map((allocation: Allocation, index) => (
          <View
            key={allocation?.label}
            style={tw(
              `flex flex-row justify-around items-center border-b border-gray-300 px-8 ${index === 0 ? 'mt-0' : 'mt-4'}`,
            )}
          >
            <View style={tw('flex flex-[0.4] justify-center p-5 pt-0')}>
              <PieGraph
                labelSize={12}
                data={allocation?.data ?? []}
                height={150}
                width={150}
                label={allocation?.title}
                innerRadius={48}
                outerRadius={60}
                customColorWithLabels={pieChartColor}
                allocationType={allocation?.label}
                showTooltip={false}
                hideLabel
              />
            </View>
            <View
              style={tw(
                'flex flex-[0.6] flex-col justify-start items-start min-w-4',
              )}
            >
              <AssetAllocationLegends
                allocationType={allocation?.label}
                data={recommendationData}
                applyMinWidthStyle
              />
            </View>
          </View>
        ))}
      <PageBottomContainer>
        <Disclosure
          text={[
            `Allocations are calculated by determining the security-level weight based on Region, Sector, and Asset classifications as defined by Morningstar, Inc. Each security-level weight is then aggregated to calculate a total weight in the respective allocation category. Sector strategies subject the investor to increased industry-specific risks.`,
            `Neither Asset Allocation nor Diversification guarantee a profit or protect against a loss in a declining market. They are methods used to help manage investment risk. Investing in international securities involves special additional risks. These risks include, but are not limited to, currency risk, political risk, and risk associated with varying accounting standards. Investing in emerging markets may accentuate these risks.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default RecommendationAllocation;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PieGraph from 'app/components/PieGraph';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import AllocationsInfo from './AllocationsInfo';

import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { PIE_CHART_COLORS_FOR_ALLOCATIONS } from 'utils/constants/pieChartColor';
import { CompareSectorAllocationProps } from './types';

function CompareSectorAllocation({
  currentPortfolioAllocationData,
  recommendedPortfolioAllocationData,
  headerTitle = 'Compare - Sectors',
}: CompareSectorAllocationProps) {
  const isSageDocument = isParamContainsSage();
  const sortLabels = (a, b) => {
    return a?.label?.localeCompare(b?.label);
  };

  const sortedCurrentPortfolioData =
    Array.isArray(currentPortfolioAllocationData) &&
    currentPortfolioAllocationData?.length > 0
      ? currentPortfolioAllocationData.sort(sortLabels)
      : [];
  const sortedRecommendedPortfolioData =
    Array.isArray(recommendedPortfolioAllocationData) &&
    recommendedPortfolioAllocationData?.length > 0
      ? recommendedPortfolioAllocationData.sort(sortLabels)
      : [];

  if (
    !Array.isArray(sortedCurrentPortfolioData) ||
    !sortedCurrentPortfolioData?.length ||
    !Array.isArray(sortedRecommendedPortfolioData) ||
    !sortedRecommendedPortfolioData?.length
  )
    return <></>;

  return (
    <ProposalPage headerTitle={headerTitle}>
      <Text
        style={tw(
          'font-inter font-medium text-default text-sm leading-[1.059rem] pb-2',
        )}
      >
        Sector Allocation
      </Text>
      <Text style={tw('font-inter text-xs leading-[1.375rem] text-neutral600')}>
        Comparing sector allocations across the major Sectors to see how your
        holdings and its underlying exposures will change with the
        recommendation.
      </Text>
      <View style={tw('w-full flex flex-row gap-4')}>
        <View style={tw('w-1/2')}>
          <View style={tw('w-full flex flex-row justify-center')}>
            <PieGraph
              data={sortedCurrentPortfolioData ?? []}
              height={160}
              width={160}
              innerRadius={65}
              outerRadius={80}
              label="Current"
              labelSize={14}
              labelFontColor="#101828"
              labelFontWeight={500}
              pieChartColor={PIE_CHART_COLORS_FOR_ALLOCATIONS}
            />
          </View>

          <View style={tw('w-full min-h-[13.75rem] my-5')}>
            <AllocationsInfo
              data={sortedCurrentPortfolioData}
              colors={PIE_CHART_COLORS_FOR_ALLOCATIONS}
            />
          </View>
        </View>
        <View style={tw('w-1/2')}>
          <View style={tw('w-full flex flex-row justify-center')}>
            <PieGraph
              data={sortedRecommendedPortfolioData ?? []}
              height={160}
              width={160}
              innerRadius={65}
              outerRadius={80}
              label="Recommended"
              labelSize={14}
              labelFontColor="#101828"
              labelFontWeight={500}
              pieChartColor={PIE_CHART_COLORS_FOR_ALLOCATIONS}
            />
          </View>

          <View style={tw('w-full min-h-[13.75rem] my-5')}>
            <AllocationsInfo
              data={sortedRecommendedPortfolioData}
              colors={PIE_CHART_COLORS_FOR_ALLOCATIONS}
            />
          </View>
        </View>
      </View>
      <PageBottomContainer>
        <Disclosure
          text={[
            `Data sourced from Morningstar, Inc. Neither Asset Allocation nor Diversification guarantee a profit or protect against a loss in a declining market. They are methods used to help manage investment risk.`,
            `The information, data, analysis, and opinions contained in this report include the confidential and proprietary information of ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} ; may include or be derived from account information provided by you or your financial representative which cannot be verified by ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'}. This information may not be copied or redistributed; does not constitute investment advice and is provided solely for informational purposes only. This material does not constitute an offer to buy or sell a security. Product suitability must be independently determined for each individual investor. Except as otherwise provided by law, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} shall not be responsible for any trading decisions, damages or other losses resulting from the use of this information, data, analysis or opinion. This report is supplemental sales literature. If applicable, this report should be preceded or accompanied by a prospectus, or equivalent, and disclosure statement.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default CompareSectorAllocation;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import Total from './Total';
import { tw } from 'utils/tailwindSetup';
import { TableProps } from './types';

function Table({
  tableHeaders,
  getMinWidth = () => 'auto',
  tableData = [],
  CustomTableRow = <></>,
  totalFields,
  showTotal = true,
  rowsPerPage = 12,
  breakPage = true,
  dataForCalculatingTotal,
}: TableProps) {
  return (
    <View style={tw(`w-full flex flex-col relative overflow-hidden`)}>
      <View style={tw('w-full flex flex-row')}>
        <View style={tw('flex flex-col flex-1 flex-nowrap')}>
          <View
            style={tw(
              'bg-neutral25 flex flex-row flex-nowrap border border-neutral200 items-center',
            )}
            fixed
          >
            {Array.isArray(tableHeaders) &&
              tableHeaders.map(({ label, type, fixedWidth = false }, index) => (
                <View
                  key={index}
                  style={tw(
                    `min-w-[${getMinWidth(type)}] ${fixedWidth ? `w-[${getMinWidth(type)}]` : 'w-auto'}`,
                  )}
                >
                  <Text
                    style={tw(
                      `flex flex-row justify-center items-center font-inter text-xs font-medium pb-2 pt-1.5 text-default text-left ${index === 0 ? 'pl-2' : 'pl-0'}`,
                    )}
                  >
                    {label}
                  </Text>
                </View>
              ))}
          </View>
          {/* table body */}
          {Array.isArray(tableData) &&
            tableData.length > 0 &&
            tableData.map((table, index) => {
              return (
                <View
                  key={index}
                  style={tw(
                    `flex flex-row w-full border border-t-0 border-neutral200`,
                  )}
                  break={
                    breakPage ? index > 0 && index % rowsPerPage === 0 : false
                  }
                >
                  {tableHeaders.map((header, headerIndex) => (
                    <View
                      key={`${index}-${headerIndex}`}
                      style={tw(
                        `font-inter flex flex-row items-center p-0.5 ${headerIndex === 0 ? 'pl-2' : 'pl-0'} font-medium text-[0.625rem] leading-[1.0625rem] text-default min-w-[${getMinWidth(header?.type)}] ${header?.fixedWidth ? `w-[${getMinWidth(header?.type)}]` : 'w-auto'}`,
                      )}
                    >
                      <CustomTableRow
                        key={index}
                        header={header}
                        rowData={table}
                      />
                    </View>
                  ))}
                </View>
              );
            })}
        </View>
      </View>
      {/* footer */}
      {showTotal && totalFields ? (
        <Total
          totalFields={totalFields}
          tableData={(dataForCalculatingTotal || tableData) ?? []}
          tableHeaders={tableHeaders}
          getMinWidth={getMinWidth}
        />
      ) : (
        <></>
      )}
    </View>
  );
}

export default Table;

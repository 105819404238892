export const HEADER_ITEMS = [
    {
        label: 'Symbol',
        type: 'SYMBOL',
        valueKey: 'ticker',
        fixedWidth: false,
    },
    {
        label: 'Name',
        type: 'NAME',
        valueKey: 'name',
        fixedWidth: true,
    },
    {
        label: 'Risk Score',
        type: 'RISK_SCORE',
        valueKey: 'riskScore',
        fieldType: 'component',
        fixedWidth: false,
    },
];

export const RISK_SCORE_METHODOLOGY = [
    {
        name: 'SPDR S&P 500 Trust',
        ticker: 'SPY',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 37,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'SPY',
        value: 96.95,
    },
    {
        name: 'iShares Core U.S. Aggregate Bond',
        ticker: 'AGG',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 9,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'SPY',
        value: 96.95,
    },
    {
        name: 'Invesco QQQ Trust Series 1',
        ticker: 'QQQ',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 46,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'SPY',
        value: 96.95,
    },
    {
        name: 'Vanguard Emerging Markets Stock Index Fund ETF',
        ticker: 'VWO',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 52,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'SPY',
        value: 96.95,
    },
    {
        name: 'iShares MSCI USA ESG Select ETF',
        ticker: 'SUSA',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 38,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'SUSA',
        value: 96.95,
    },
    {
        name: 'Apple,Inc.',
        ticker: 'AAPL',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 61,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'AAPL',
        value: 96.95,
    },
    {
        name: 'Microsoft,Inc.',
        ticker: 'MSFT',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 58,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'MSFT',
        value: 96.95,
    },
    {
        name: 'Qualcomm, Inc.',
        ticker: 'QUAL',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 38,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: 'MSFT',
        value: 96.95,
    },
    {
        name: 'Allocation of 60% Equity / 40% Fixed Income Sample Portfolio',
        ticker: '-',
        weight: 100,
        assetClass: 'US Bonds',
        riskScore: 26,
        price: 96.95,
        region: 'Developed Markets United States',
        sector: 'Information Technology',
        proxy: 'AGG',
        id: '-',
        value: 96.95,
    },
];
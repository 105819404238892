import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import {
  BENCHMARK_KEY,
  CURRENT_PORTFOLIO_KEY,
  RECOMMENDED_PORTFOLIO_KEY,
} from 'app/components/RiskStatisticsGraph/constants';

import { tw } from 'utils/tailwindSetup';
import { LegendProps } from './types';

function Legends({
  data,
  isTemplateCurrentPortfolio = false,
  benchmarkName = '',
}: LegendProps) {
  const filteredLegends = useMemo(() => {
    const legendItems = [
      {
        key: CURRENT_PORTFOLIO_KEY,
        title: 'Current Portfolio',
        color: '#7B73E4',
      },
      {
        key: RECOMMENDED_PORTFOLIO_KEY,
        title: isTemplateCurrentPortfolio ? benchmarkName : 'Recommended',
        color: isTemplateCurrentPortfolio ? '#00B186' : '#FFA32C',
      },
      {
        key: BENCHMARK_KEY,
        title: `Relative Security - ${benchmarkName}`,
        color: '#00B186',
      },
    ];
    return legendItems.filter(
      legend =>
        Array.isArray(data) &&
        data?.length > 0 &&
        data.some(item => item?.[legend?.key]),
    );
  }, [isTemplateCurrentPortfolio, data, benchmarkName]);

  return (
    <View style={tw('w-full flex flex-row gap-4 mt-4 ml-4')}>
      {Array.isArray(filteredLegends) &&
        filteredLegends.length > 0 &&
        filteredLegends.map(legend => (
          <View key={legend?.key} style={tw('flex flex-row gap-2')}>
            <View
              style={tw(
                `bg-[${legend?.color ?? '#000'}] h-3 w-3 rounded-[1px]`,
              )}
            />
            <Text
              style={tw(
                'font-inter text-[0.625rem] leading-[0.9375rem] text-default min-h-3',
              )}
            >
              {legend?.title ?? ''}
            </Text>
          </View>
        ))}
    </View>
  );
}

export default Legends;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { ChartLabelProps } from './types';

function ChartLabel({
  firstChartLabel = '',
  secondChartLabel = '',
}: ChartLabelProps) {
  return (
    <View style={tw('w-full flex flex-row gap-4 justify-center mt-1')}>
      <Text
        style={tw(
          'font-inter basis-5/12 text-[0.688rem] leading-4 font-medium text-default text-center',
        )}
      >
        {firstChartLabel ?? ''}
      </Text>
      <Text
        style={tw(
          'font-inter basis-5/12 text-[0.688rem] leading-4 font-medium text-default text-center ml-4',
        )}
      >
        {secondChartLabel ?? ''}
      </Text>
    </View>
  );
}

export default ChartLabel;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { FooterProps } from './types';

const baseTextStyle = tw(
  'font-inter text-neutral400 text-[0.625rem] leading-3 px-2.5 h-4',
);
const baseBorderStyle = tw('border-r border-neutral400');
const capitalizeStyle = tw('capitalize');

function Footer({
  advisorInfo: { firmName = '', name = '', email = '' },
}: FooterProps) {
  return (
    <View
      style={tw(
        'flex flex-row justify-between border-t-[0.0625rem] border-t-[#929496] h-16',
      )}
    >
      <View style={tw('flex flex-row justify-between pt-2.5')}>
        {name && (
          <Text style={[baseTextStyle, baseBorderStyle, capitalizeStyle]}>
            Prepared by&nbsp;
            {name}
          </Text>
        )}
        {email && <Text style={[baseTextStyle, baseBorderStyle]}>{email}</Text>}
        {firmName && (
          <Text style={[baseTextStyle, capitalizeStyle]}>{firmName}</Text>
        )}
      </View>
      <Text
        style={tw('font-inter w-10 h-full text-[0.625rem] leading-3 p-3')}
        render={({ pageNumber }) => `${pageNumber}`}
      />
    </View>
  );
}

export default Footer;

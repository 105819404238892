import { Allocation } from './types';

export const allocations = recommendationData => {
  return [
    {
      title: 'Regional Allocation',
      label: 'regionAllocation',
      data: recommendationData?.regionAllocation,
      inludeInSnapshot: false,
    },
    {
      title: 'Sector Allocation',
      label: 'sectorAllocation',
      data: recommendationData?.sectorAllocation,
      inludeInSnapshot: true,
    },
    {
      title: 'Asset Allocation',
      label: 'assetAllocation',
      data: recommendationData?.assetAllocation,
      inludeInSnapshot: true,
    },
  ] as Allocation[];
};

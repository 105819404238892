import React, { useCallback } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { formatDigitUptoDecimalPlaces } from 'utils/helpers';
import { tw } from 'utils/tailwindSetup';
import { HeaderProps } from './types';

function Header({ label, value, lossGainPercentage, valueType }: HeaderProps) {
  const showValue = useCallback(
    value => {
      const lossGainValue = formatDigitUptoDecimalPlaces(
        Math.abs(value) || 0,
        0,
      );
      const lossGainPercentageValue =
        Math.round(lossGainPercentage * 100 || 0) / 100 || 0;
      if (valueType === 'LOSS')
        return {
          value: `- $${lossGainValue}`,
          percentage: `-${lossGainPercentageValue}%`,
        };
      return {
        value: `+ $${lossGainValue}`,
        percentage: `${lossGainPercentageValue}%`,
      };
    },
    [lossGainPercentage, valueType],
  );
  return (
    <View
      style={tw(
        `text-[${valueType === 'LOSS' ? '#7B73E4' : '#27525E'}] w-full flex flex-col justify-center items-center`,
      )}
    >
      <Text style={tw('font-inter font-medium text-xs my-[0.3125rem]')}>
        {label ?? ''}
      </Text>
      <Text style={tw('font-inter font-medium text-base leading-[1.21rem]')}>
        {showValue(value)?.value ?? ''}
      </Text>
      <Text style={tw('font-extralight text-[0.625rem] mt-1')}>
        {`(${showValue(lossGainPercentage)?.percentage ?? 0})`}
      </Text>
    </View>
  );
}

export default Header;

import React from 'react';
import ReactPDFChart from 'react-pdf-charts';
import { LineChart, Line, XAxis, YAxis, ReferenceLine } from 'recharts';
import { formatDigitUptoDecimalPlaces } from 'utils/helpers';
import { xAxisDomain, xAxisTicksFormatter } from './utils';
import { LineGraphProps } from './types';

function LineGraph({
  data,
  layoutConfig,
  chartConfig,
  dataKeyToPortfolioMapping = {},
}: LineGraphProps) {
  const {
    height = 100,
    width = 100,
    margin = { top: 15, right: 30, left: 20, bottom: 5 },
  } = layoutConfig;
  const { graphColors = {} } = chartConfig;

  const getKeys = (data = {}) => {
    if (data) {
      const keys = Object.keys(data);
      return keys && keys?.length > 0 ? keys : [];
    }
    return [];
  };

  return (
    <ReactPDFChart>
      <LineChart width={width} height={height} data={data} margin={margin}>
        <XAxis
          dataKey="date"
          ticks={xAxisDomain(data)}
          tickFormatter={xAxisTicksFormatter}
          padding={{
            left: 30,
          }}
          tick={{
            fill: '#98A2B3',
          }}
          stroke="#98A2B3"
          fontSize="12px"
        />
        <YAxis
          domain={[dataMin => dataMin - dataMin * 0.2, 'auto']}
          tickCount={8}
          tickFormatter={val => `$${formatDigitUptoDecimalPlaces(val)}`}
          tick={{
            fill: '#98A2B3',
          }}
          fontSize="12px"
          stroke="#98A2B3"
        />

        <ReferenceLine
          y="100"
          strokeDasharray="3 3"
          label={{
            fill: '#98A2B3',
            position: 'right',
            value: '$ 100',
            width: '60',
            fontSize: '12',
          }}
        />
        {Array.isArray(data) && data?.length > 0 ? (
          getKeys(data[0])?.map(dataKey => {
            const chartKey = dataKeyToPortfolioMapping?.[dataKey];

            return (
              <Line
                key={chartKey ? dataKey : ''}
                dataKey={chartKey ? dataKey : ''}
                name={chartKey ?? ''}
                type="natural"
                dot={false}
                stroke={graphColors?.[chartKey] ?? '#F63D68'}
                isAnimationActive={false}
              />
            );
          })
        ) : (
          <></>
        )}
      </LineChart>
    </ReactPDFChart>
  );
}

export default LineGraph;

import React from 'react';
import { Page } from '@react-pdf/renderer';
import { ProposalPageProps } from './types';
import { tw } from 'utils/tailwindSetup';
import Header from '../Header';

function ProposalPage({ children, headerTitle }: ProposalPageProps) {
  return (
    <Page
      size={{ width: 800, height: 1130 }}
      style={tw('p-12 pb-0 font-inter relative')}
    >
      <Header title={headerTitle ?? ''} />
      {children ?? <></>}
    </Page>
  );
}

export default ProposalPage;

export const fetchProposalData = async (proposalId: string) => {
  const response = await fetch(
    `${process.env.REACT_APP_INVESTMENT_PROPOSAL_API}/investment-proposals-api?proposal_id=${proposalId}`,
  );

  if (!response.ok) {
    throw new Error('Network response was not ok');
  }

  return response.json();
};

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Table from 'app/components/Table';
import { HEADER_ITEMS, GOE_CMA_TABLE_DATA } from './constants';
import { getMinWidthForHoldings } from './utils';
import HoldingsTableRow from 'app/pages/RecommendedPortfolio/Snapshot/HoldingsTableRow';

interface AppendixCMAProps {
  headerTitle?: string;
}

const mainTitleStyles = tw(
  'text-default font-medium text-[0.875rem] leading-4 font-inter',
);

const contentStyles = tw(
  'text-neutral600 font-normal text-xs leading-5 font-inter',
);

const viewStyles = tw('my-4');

function AppendixCMA({ headerTitle = 'Appendix CMA' }: AppendixCMAProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={viewStyles}>
        <Text style={mainTitleStyles}>
          Capital Market Assumptions as of 05/21/2024
        </Text>
      </View>
      <View>
        <Text style={contentStyles}>
          The below Capital Market Assumptions (CMAs) represent 10-year periods.
          The CMAs represent an average of the capital market assumptions
          reported across several asset managers including JP Morgan, Morgan
          Stanley, BNY Mellon, Wells Fargo, and BlackRock to get a "wisdom of
          the experts" estimate. Each of these managers have significant teams
          working on these capital market assumptions that underlie several
          important decisions for them and their investors alike. Forecasts are
          updated on an annual basis. A full list of CMA sources is available
          upon request.
        </Text>
      </View>
      <View style={tw('my-4')}>
        <Table
          tableHeaders={HEADER_ITEMS}
          tableData={GOE_CMA_TABLE_DATA}
          getMinWidth={getMinWidthForHoldings}
          CustomTableRow={HoldingsTableRow}
          totalFields={{
            types: ['WEIGHT', 'PRICE'],
            values: ['weight', 'price'],
          }}
          showTotal={false}
        />
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default AppendixCMA;

import React from 'react';

function LabelContent(props) {
  const { x, value } = props;

  if (!props || Object.keys(props).length === 0) return <></>;

  return (
    <text
      x={x + 15}
      y={142}
      dy={-4}
      fill="#7D7D7D"
      fontSize={10}
      textAnchor="middle"
    >
      {`${value ?? 0}%`}
    </text>
  );
}

export default LabelContent;

import React, { ReactNode, useEffect, useState } from 'react';
import { renderToString } from 'react-dom/server';
import { Canvg } from 'canvg';
import { Image } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';

interface RenderToImageProps {
  children: ReactNode;
}

export const svgToDataUri = async (svgString: string) => {
  try {
    const canvas: HTMLCanvasElement = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      const v = Canvg.fromString(context, svgString.trim());
      await v.render();
      const dataUri = canvas.toDataURL('image/png');
      return dataUri;
    }
  } catch (error) {
    return '';
  }
};

function RenderToImage({ children }: RenderToImageProps) {
  const [imageSrc, setImageSrc] = useState<string | undefined>('');

  useEffect(() => {
    const convertSvg = async () => {
      const data = await svgToDataUri(renderToString(children));
      setImageSrc(data);
    };
    convertSvg();
  }, [children]);

  return (
    <>
      {imageSrc !== '' && <Image src={imageSrc} style={tw('h-full w-full')} />}
    </>
  );
}

export default RenderToImage;

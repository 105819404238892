import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { riskContent } from './utils';
import {
  riskCapacityColor,
  riskPreferenceColor,
} from 'app/components/RiskBand/colors';
import { RiskScoreContentProps } from './types';

function RiskScoreContent({
  score = 0,
  riskType = 'RISK_CAPACITY',
}: RiskScoreContentProps) {
  const { title, descriptorText, content } = riskContent(riskType, score);
  const baseColor =
    riskType === 'RISK_CAPACITY' ? riskCapacityColor : riskPreferenceColor;

  return (
    <View
      style={tw(
        'border border-neutral200 rounded flex flex-col items-center justify-center flex-1',
      )}
    >
      <View
        style={tw(
          'min-h-[4.375rem] flex flex-col justify-between items-center',
        )}
      >
        <Text style={tw('font-semibold text-xs text-black mt-4 mx-2')}>
          {title ?? ''}
        </Text>
        <View style={tw('relative')}>
          <View
            style={tw(
              `absolute ${riskType === 'RISK_CAPACITY' ? 'left-16' : 'left-14'} -top-4 w-0 h-0 bg-white border-t-0 border-[0.625rem] border-white border-b-[${baseColor}] rotate-[360deg]`,
            )}
          />
          <Text
            style={tw(
              `bg-[${baseColor}] text-neutral25 rounded-[0.9375rem] py-1 px-3 text-xs`,
            )}
          >
            {descriptorText ?? ''}
          </Text>
        </View>
      </View>

      <Text
        style={tw(
          `font-inter font-semibold text-[2.813rem] leading-[3.404rem] mt-8 mx-4 text-[${baseColor}]`,
        )}
      >
        {score ?? 0}
      </Text>

      <Text
        style={tw(
          'font-inter text-neutral text-sm leading-[1.2rem] w-[80%] min-h-[5.625rem] my-4',
        )}
      >
        {content ?? ''}
      </Text>
    </View>
  );
}

export default RiskScoreContent;

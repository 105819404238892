import React from 'react';
import { Text } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { tw } from 'utils/tailwindSetup';
import { WealthDisclosureProps } from './types';

const textStyle = tw(
  'mb-[1.875rem] text-neutral600 font-normal text-[0.6875rem] leading-5 font-inter',
);

function WealthDisclosure({
  headerTitle = 'TIFIN Wealth Disclosure',
}: WealthDisclosureProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <Text style={textStyle}>
        The information, data, analysis, and opinions contained in this report
        are confidential; may include or be derived from information provided by
        you that cannot be verified; may not be copied or redistributed; does
        not constitute investment advice; are provided solely for informational
        purposes and do not constitute an offer to buy or sell a security; and
        are not warranted to be correct, complete, or accurate. Except as
        otherwise provided by law, TIFIN Wealth shall not be responsible for any
        trading decisions, damages, or other losses resulting from the use of
        this information, data, analysis, or opinion.
      </Text>
      <Text style={textStyle}>
        Risk tolerance is an investor's general ability to withstand risk
        inherent in investing. This risk tolerance questionnaire is designed to
        determine your risk tolerance and is judged based on several factors,
        which may include: time horizon, long-term goals, and expectations, and
        short-term risk attitudes. The Advisor uses their own experience and
        subjective evaluation of your answers to help determine your risk
        tolerance. There is no guarantee that the risk assessment questionnaire
        will accurately assess your tolerance to risk.
      </Text>
      <Text style={textStyle}>
        FINRA defines "Risk Tolerance" as "a customer's ability and willingness
        to lose some or all of [the] original investment in exchange for greater
        potential returns." We believe the prevalent practice of measuring just
        the willingness for risk, or the psychological tolerance is
        insufficient, as this can be quite different than the losses the client
        can withstand given their unique personal and financial situations. To
        be consistent with the guidance of FINRA's suitability rule, our client
        risk score measures both a client's willingness, what we call "Risk
        Preferences", and the ability, or "Risk Capacity", to lose money for
        potential gains. Asset allocation and diversification do not guarantee
        investment returns or eliminate risk of loss. They are investment
        methods used to help manage risk and volatility within a portfolio.
      </Text>
      <Text style={textStyle}>
        Portfolio Risk Scores come from Value at Risk (VaR). VaR is used to
        assess the downside risk of a security or a portfolio,specifically 99%
        VaR (annualized). VaR is designed to look at downside returns, and 99%
        VaR is specifically concerned with the worst 1% of potential outcomes.
        Variance-Covariance method is used to calculate the VaR using average
        annual return and annual standard deviation.
      </Text>
      <Text style={textStyle}>
        Past performance is not a guarantee or a predictor of future results of
        either the indices or any particular investment and involves certain
        risks and uncertainties, which are difficult to predict. Investments are
        subject to risk, including the loss of principal. There is no guarantee
        that any investing goal will be met.
      </Text>
      <PageBottomContainer />
    </ProposalPage>
  );
}

export default WealthDisclosure;

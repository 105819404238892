import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import Header from './Header';

import divider from 'assets/divider.png';
import { tw } from 'utils/tailwindSetup';
import { LossGainBandProps } from './types';

function LossGainBand({ lossGain }: LossGainBandProps) {
  const gain = lossGain?.gain?.value ?? 0;
  const loss = lossGain?.loss?.value ?? 0;

  const lossPercentage = (lossGain?.loss && lossGain?.loss?.percentage) ?? 0;
  const gainPercentage = (lossGain?.gain && lossGain?.gain?.percentage) ?? 0;

  const baseLabelStyle = tw(
    'font-medium font-inter text-neutral400 text-[0.625rem] leading-4',
  );

  return (
    <View style={tw('w-full')}>
      <View style={tw('w-full flex flex-row justify-between items-center')}>
        <View style={tw('w-3/6 flex flex-col justify-center items-center')}>
          <Header
            label="Possible Loss"
            value={loss}
            lossGainPercentage={lossPercentage}
            valueType="LOSS"
          />
        </View>
        <View style={tw('w-3/6 flex flex-col justify-center items-center')}>
          <Header
            label="Possible Gains"
            value={gain}
            lossGainPercentage={gainPercentage}
            valueType="GAIN"
          />
        </View>
      </View>
      <View
        style={tw(
          'relative w-full flex flex-row bg-[#E8E7EA] rounded-[2.5rem] mt-5',
        )}
      >
        <View style={tw('flex flex-col h-3 w-3/6')}>
          <View
            style={tw(
              `h-full ${lossPercentage >= 100 ? 'w-full' : `w-[${Math.round(lossPercentage)}%]`} bg-[#033E3E] ml-auto`,
            )}
          />
        </View>
        <Text
          style={tw(
            'absolute left-[49%] text-xs text-neutral400 -top-[1.5625rem]',
          )}
        >
          $0
        </Text>
        <View style={tw('h-3 w-3/6')}>
          <View
            style={tw(
              `h-full ${gainPercentage > 100 ? 'w-full' : `w-[${Math.round(gainPercentage)}%]`} bg-[#7B73E4]`,
            )}
          />
        </View>
        <Image style={tw('absolute h-3 w-[1px] left-1/2')} src={divider} />
      </View>
      <View style={tw('w-full flex flex-row justify-between')}>
        <View style={tw('min-h-4 mt-2.5')}>
          <Text style={baseLabelStyle}>-100% Loss</Text>
        </View>
        <View style={tw('min-h-4 mt-2.5')}>
          <Text style={baseLabelStyle}>100% Gain</Text>
        </View>
      </View>
    </View>
  );
}

export default LossGainBand;

import React from 'react';
import { Cell, Label, Pie, PieChart } from 'recharts';
import ReactPDFChart from 'react-pdf-charts';
import CustomLabel from './CustomLabel';
import { PieChartProps } from './types';

function PieGraph({
  data,
  height,
  width,
  innerRadius,
  outerRadius,
  label,
  labelSize = 18,
  startAngle = 180,
  endAngle = -180,
  labelFontWeight = 700,
  labelFontColor = '#292929',
  customColorWithLabels,
  allocationType,
  strokeWidth,
  blendStroke,
  pieChartColor = [],
  customLabelSpacing = 18,
}: PieChartProps) {
  return (
    <ReactPDFChart>
      <PieChart width={width} height={height}>
        <Pie
          data={data}
          cx="50%"
          cy="50%"
          innerRadius={innerRadius ?? 60}
          outerRadius={outerRadius ?? 80}
          fill="#8884d8"
          dataKey="data"
          paddingAngle={5}
          strokeWidth={strokeWidth ? strokeWidth : 1}
          blendStroke={blendStroke ? blendStroke : false}
          isAnimationActive={false}
          startAngle={startAngle}
          endAngle={endAngle}
          labelLine={false}
        >
          {Array.isArray(data) &&
            data.length > 0 &&
            data.map((entry, index) => {
              const labelColor =
                customColorWithLabels &&
                customColorWithLabels(
                  allocationType ? allocationType : '',
                )?.find(item => item.label === entry?.label && item);

              return (
                <Cell
                  key={`cell-${index}`}
                  fill={labelColor?.color ?? pieChartColor?.[index] ?? '#000'}
                />
              );
            })}
          {label ? (
            <Label
              width={30}
              position="center"
              content={
                <CustomLabel
                  labelSize={labelSize}
                  value={label}
                  labelFontColor={labelFontColor}
                  labelFontWeight={labelFontWeight}
                  customLabelSpacing={customLabelSpacing}
                />
              }
            />
          ) : null}
        </Pie>
      </PieChart>
    </ReactPDFChart>
  );
}

export default PieGraph;

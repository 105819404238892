import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import App from 'App';
import { ProposalProps } from 'app/pages/ProposalPdf/types';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      retry: 1,
      retryDelay: attemptIndex => 1000 * 2 ** attemptIndex,
      cacheTime: 1000 * 60 * 60, // 60 minutes of cache time
      staleTime: 1000 * 30, // 30 seconds of stale time
    },
  },
});

function Proposals({
  wealthProposalId,
  pageList,
  editablePageContent,
  templateType,
  updateLoadingStateForPdfApp,
}: ProposalProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <App
        pageList={pageList}
        wealthProposalId={wealthProposalId}
        editablePageContent={editablePageContent}
        templateType={templateType}
        updateLoadingStateForPdfApp={updateLoadingStateForPdfApp}
      />
    </QueryClientProvider>
  );
}

export default Proposals;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { DisclosureProps } from './types';

const baseTextStyle = tw(
  'font-inter text-neutral text-[0.5rem] leading-[9.68px] mb-2.5',
);

function Disclosure({ text, minHeight = true, children }: DisclosureProps) {
  return (
    <View style={tw(`m-1 ${minHeight ? 'min-h-[4.375rem]' : 'h-auto'} w-full`)}>
      {!children ? (
        Array.isArray(text) &&
        text.length > 0 &&
        text.map((item, index) => (
          <Text key={index} style={baseTextStyle}>
            {item}
          </Text>
        ))
      ) : (
        <View style={baseTextStyle}>{children}</View>
      )}
    </View>
  );
}

export default Disclosure;

import { createTw } from 'react-pdf-tailwind';

export const tw = createTw(
  {
    theme: {
      fontFamily: {
        inter: ['Inter'],
      },
      extend: {
        colors: {
          default: '#101828',
          neutral: '#98A2B3',
          neutral2: '#667085',
          neutral25: '#FCFDFD',
          neutral50: '#F9FAFB',
          neutral100: '#F2F4F7',
          neutral200: '#E4E7EC',
          neutral300: '#D0D5DD',
          neutral400: '#98A2B3',
          neutral500: '#667085',
          neutral600: '#475467',
          neutral800: '#1D2939',
          darkGreen: '#033E3E',
        },
      },
    },
  },
  {
    ptPerRem: 16,
  },
);

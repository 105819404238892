import { format, isValid } from 'date-fns';
import { RiskDetailsProps } from './types';

export const riskContent = (type, score) => {
  switch (type) {
    case 'RISK_CAPACITY':
      return {
        title: 'Risk Capacity',
        content: `A Risk Capacity score of ${score} means you are capable of losing up to ${score}% of your portfolio’s value without having major adverse impact to your life.`,
        descriptorText: 'Ability to take on Risk',
      };

    default:
      return {
        title: 'Risk Preference',
        content: `A Risk Preference score of ${score} means that you are willing to lose up to ${score}% of your portfolio’s value.`,
        descriptorText: 'Willingness for Risk',
      };
  }
};

const riskScoreEditReason = reason => {
  switch (reason) {
    case 'MARKET_CONDITIONS':
      return 'Market conditions affected the outcome';
    case 'CLIENT_DEMANDS':
      return 'Client demands a specific score';
    case 'OVER_CONFIDENT':
      return 'Over/under confident at the time of the survey';
    default:
      return reason ?? '';
  }
};

export function displayRiskScoreEditDetails(riskDetails: RiskDetailsProps) {
  const riskCapacityReason = riskDetails?.riskCapacityReason || '';
  const riskPreferenceReason = riskDetails?.riskPreferenceReason || '';

  const createdAt = riskDetails?.createdAt
    ? new Date(riskDetails?.createdAt ?? '')
    : new Date();

  const formattedDate = isValid(createdAt)
    ? format(createdAt, 'MM/dd/yyyy')
    : '';

  const riskCapacityReasonText = riskCapacityReason
    ? riskScoreEditReason(riskCapacityReason)
    : '';
  const riskPreferenceReasonText = riskPreferenceReason
    ? (riskCapacityReasonText ? ',' : '') +
      ` ${riskScoreEditReason(riskPreferenceReason)}`
    : '';

  const disclaimerText = `Disclaimer: Please note that the Risk Capacity and Risk Preference scores were updated on ${formattedDate} due to ${riskCapacityReasonText} ${riskPreferenceReasonText}.`;
  return riskCapacityReason || riskPreferenceReason ? disclaimerText : '';
}

export const getMinWidthForHoldings = (type: string) => {
    switch (type) {
        case 'SYMBOL':
            return '130px';
        case 'NAME':
            return '380px';
        case 'RISK_SCORE':
            return '100px';
        default:
            return '100px';
    }
};
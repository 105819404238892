import React from 'react';
import { View, Text, Image } from '@react-pdf/renderer';
import { tw } from 'utils/tailwindSetup';
import { HeaderProps } from './types';

function Header({ title = '', advisorLogo = '' }: HeaderProps) {
  return (
    <View
      style={tw(
        `flex flex-row justify-between border-b-[0.0625rem] border-neutral200 pb-2 mb-8`,
      )}
      fixed
    >
      {title && (
        <Text
          style={tw(
            'font-inter text-default font-inter font-medium text-[1.375rem] leading-[1.6875rem]',
          )}
        >
          {title ?? ''}
        </Text>
      )}
      {advisorLogo && (
        <Image src={advisorLogo} style={tw('h-12 w-24 object-contain')} />
      )}
    </View>
  );
}

export default Header;

import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { format, sub } from 'date-fns';

import RiskStatisticsGraph from 'app/components/RiskStatisticsGraph';
import ChartLabel from './ChartLabel';
import Legends from './Legends';

import { tw } from 'utils/tailwindSetup';
import { StatisticsInfoProps } from './types';

const cardFooterStyles = tw(
  'font-inter text-[#344054] text-[0.625rem] leading-5 italic mt-5',
);

function StatisticsInfo({
  data,
  statisticType,
  period = 0,
  isTemplateCurrentPortfolio = false,
  benchmarkName = '',
}: StatisticsInfoProps) {
  const header =
    statisticType === 'VOLATILITY_DRAWDOWN'
      ? ' Volatility and Max Drawdown'
      : 'Up and Down Capture';

  const filteredDataBasedOnTemplate = useMemo(() => {
    if (Array.isArray(data) && data.length > 0) {
      if (isTemplateCurrentPortfolio) {
        return data.map(item => ({
          currentPortfolio: item?.currentPortfolio ?? 0,
          benchmark: item?.benchmark ?? 0,
          value: item?.value ?? 0,
        }));
      } else return data;
    }
    return [];
  }, [data, isTemplateCurrentPortfolio]);

  return (
    <>
      <Text
        style={tw(
          'font-inter font-medium text-default text-base leading-[1.21rem] my-5',
        )}
      >
        {header}
      </Text>
      <View
        style={tw(
          'w-full relative py-5 px-10 bg-white border border-neutral200 rounded-lg min-h-[280px]',
        )}
      >
        <View>
          {period === 0 ? (
            <Text>
              The historical common period is less than 1 year and is
              insufficient to calculate {header}
            </Text>
          ) : (
            <View style={tw('flex flex-row justify-end')}>
              <Text
                style={tw(
                  'font-inter text-neutral400 text-[0.5rem] leading-[0.605rem] pb-2',
                )}
              >
                <Text style={tw('font-bold')}>{period} year period </Text>(
                {format(
                  sub(new Date(), {
                    years: period,
                  }),
                  'MM-dd-yyyy',
                )}{' '}
                to {format(new Date(), 'MM-dd-yyyy')})
              </Text>
            </View>
          )}
        </View>

        {statisticType === 'UP_DOWN_CAPTURE' && (
          <Text
            style={tw(
              'absolute top-1/2 left-10 -rotate-90 font-inter text-[0.688rem] leading-4 font-medium text-default',
            )}
          >
            % Capture
          </Text>
        )}
        <RiskStatisticsGraph
          data={filteredDataBasedOnTemplate}
          isTemplateCurrentPortfolio={isTemplateCurrentPortfolio}
        />
        <View style={tw('w-[600px] ml-6')}>
          <ChartLabel
            firstChartLabel={
              statisticType === 'VOLATILITY_DRAWDOWN'
                ? 'Volatility'
                : 'Up Capture'
            }
            secondChartLabel={
              statisticType === 'VOLATILITY_DRAWDOWN'
                ? 'Drawdown'
                : 'Down Capture'
            }
          />
          <Legends
            data={filteredDataBasedOnTemplate}
            benchmarkName={benchmarkName}
          />
        </View>
      </View>
      <View>
        {statisticType === 'VOLATILITY_DRAWDOWN' ? (
          <Text style={cardFooterStyles}>
            <Text style={tw('font-black')}>Volatility,</Text> as defined by
            standard deviation, is one indicator of portfolio risk. Too high
            means the portfolio is at risk of large losses. Too low, the
            portfolio's potential may be restricted.{' '}
            <Text style={tw('font-black')}>Max Drawdown</Text> is a
            peak-to-trough decline of a portfolio. E.g. If a trading account has
            $10,000 in it, and the funds drop to $7,000 before moving back above
            $10,000, then the drawdown in this portfolio is 30%.
          </Text>
        ) : (
          <Text style={cardFooterStyles}>
            Up capture and Down capture shows us whether a given fund has gained
            more or lost less than a broad market benchmark during periods of
            market strength and weakness. The relative security is selected by
            the Advisor. Returns are net of Expense Ratios and net of the
            Advisor’s advisory fee.
          </Text>
        )}
      </View>
    </>
  );
}

export default StatisticsInfo;

import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';

import CompareRiskBand from './CompareRiskBand';
import PortfolioRiskScoreCard from './PortfolioRiskScoreCard';

import currentPortfolioIcon from 'assets/currentPortfolioIcon.png';
import recommendedPortfolioIcon from 'assets/recommendedPortfolioIcon.png';

import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { populateRiskScoreData } from './utils';
import { CompareRiskProps } from './types';

function CompareRisk({
  compareRiskData: {
    accountName = '',
    recommendedPortfolioData,
    currentPortfolioData,
    models,
    headerTitle = 'Compare - Risk Scores',
  },
  showExpenseRatio = false,
}: CompareRiskProps) {
  const isSageDocument = isParamContainsSage();
  const riskScoreDataForCurrentPortfolio = useMemo(() => {
    return populateRiskScoreData(currentPortfolioData ?? []) ?? null;
  }, [currentPortfolioData]);

  const riskScoreDataForRecommendedPortfolio = useMemo(() => {
    return populateRiskScoreData(recommendedPortfolioData ?? []) ?? null;
  }, [recommendedPortfolioData]);

  if (
    (!recommendedPortfolioData?.riskDetails ||
      !recommendedPortfolioData?.lossGain) &&
    (!currentPortfolioData?.riskDetails || !currentPortfolioData?.lossGain)
  )
    return <></>;

  return (
    <>
      {riskScoreDataForCurrentPortfolio &&
      riskScoreDataForRecommendedPortfolio ? (
        <ProposalPage headerTitle={headerTitle}>
          <View>
            <View style={tw('w-full mb-[0.9375rem]')}>
              <View style={tw('w-full flex flex-row gap-[1.5rem]')}>
                <Text
                  style={tw(
                    'font-inter text-sm leading-[1.059rem] text-neutral500 mb-4 w-1/2',
                  )}
                >
                  Current Portfolio
                </Text>
                <Text
                  style={tw(
                    'font-inter text-sm leading-[1.059rem] text-neutral500 mb-4 ml-4 w-1/2',
                  )}
                >
                  Recommended Portfolio
                </Text>
              </View>
              <View style={tw('flex flex-row gap-[1.5rem] flex-wrap')}>
                <PortfolioRiskScoreCard
                  portfolioType="CURRENT"
                  portfolioData={currentPortfolioData}
                  cardHeader={accountName || 'Current Portfolio'}
                  labelIcon={currentPortfolioIcon}
                  riskScoreData={riskScoreDataForCurrentPortfolio}
                  showExpenseRatio={showExpenseRatio}
                />
                <PortfolioRiskScoreCard
                  portfolioType="RECOMMENDED"
                  portfolioData={recommendedPortfolioData}
                  cardHeader={
                    Array.isArray(models) && models?.length
                      ? recommendedPortfolioData?.baseModel?.name
                      : 'Recommended Portfolio'
                  }
                  labelIcon={recommendedPortfolioIcon}
                  riskScoreData={riskScoreDataForRecommendedPortfolio}
                  showExpenseRatio={showExpenseRatio}
                />
              </View>
            </View>
            {/* risk band */}
            <View style={tw('w-full m-auto')}>
              <Text
                style={tw('font-inter text-black text-[0.625rem] leading-4')}
              >
                Possible gains and losses shown will differ from your portfolio
                risk score due to the inclusion of advisory fees in these
                calculations.
              </Text>

              <CompareRiskBand
                currentPortfolioData={currentPortfolioData}
                recommendedPortfolioData={recommendedPortfolioData}
              />
            </View>
          </View>
          <PageBottomContainer>
            <Disclosure
              text={[
                `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} uses parametric Value-at-Risk (VaR) to calculate the portfolio’s Risk Score. VaR is an industry standard measure of risk; it measures the potential risk of loss for an investment or portfolio over a given period of time with a specific confidence level. ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} uses a 1-year VaR at a 99% confidence level to calculate the Risk Score. This means over a 1-year period there is a 99% probability that the portfolio will not lose more than the Risk Score. This also means over a 1-year period there is a 1% probability that the portfolio could lose more than the Risk Score.`,
                `Possible Losses are represented as a Value-at-Risk metric with a 99% confidence level, meaning there is a 1% chance your current portfolio value could lose up to this amount or more in a given year (Portfolio Value x Risk Score %). Possible Gains represents the potential portfolio gains in a given year using the same distribution as potential losses. This means there is a 1% chance that your portfolio could gain this value or more in a given year. Both Gains and Losses are hypothetical, representing no guarantee for future loss or future gains. It is possible that actual gains or losses could be more or less favorable than shown. The figures shown reflect the deduction of the advisor’s annual advisory fee, but may not include commissions, sales charges, custodial fees or platform fees, which, if included would result in lower values. For more information on methodology, please see appendix. `,
              ]}
            />
          </PageBottomContainer>
        </ProposalPage>
      ) : (
        <></>
      )}
    </>
  );
}

export default CompareRisk;

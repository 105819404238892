import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import RiskScoreCircle from 'app/components/RiskScoreCircle';
import { tw } from 'utils/tailwindSetup';
import { formatToMillions } from 'utils/helpers';
import { HoldingsTableProps } from './types';

function HoldingsTableRow({ header, rowData }: HoldingsTableProps) {
  const {
    type = '',
    fieldType = '',
    valueKey = '',
    postfix = '',
    prefix = '',
  } = header;

  if (fieldType === 'component' && type === 'RISK_SCORE') {
    return (
      <View style={tw('flex flex-row justify-center items-center')}>
        <RiskScoreCircle
          score={rowData?.[valueKey]}
          height={27}
          width={27}
          textSize={8}
        />
      </View>
    );
  }
  if (type === 'WEIGHT') {
    return <Text>{+rowData?.[valueKey]?.toFixed(2)}%</Text>;
  }
  if (type === 'VALUE') {
    const price = +rowData?.[valueKey] ?? 0;
    return (
      <Text>
        {prefix} {!isNaN(price) ? formatToMillions(price) : 0}
      </Text>
    );
  }
  return (
    <Text>
      {rowData?.[valueKey] ?? ''} {postfix ?? ''}
    </Text>
  );
}

export default HoldingsTableRow;

import React from 'react';
import { Cell, Pie, PieChart } from 'recharts';
import ReactPDFChart from 'react-pdf-charts';

import { tw } from 'utils/tailwindSetup';
import { RiskScoreCircleProps } from './types';

const RiskScoreCircle = ({
  score,
  textSize = 10,
  width = 50,
  height = 50,
  pieChartBackgroundColor = '#fff',
  customPieChartFillColor = '',
}: RiskScoreCircleProps) => {
  const centerX = width / 2;
  const centerY = height / 2;

  return (
    <ReactPDFChart>
      <PieChart
        width={width}
        height={height}
        style={tw('flex justify-center items-center')}
      >
        <circle
          cx={centerX}
          cy={centerY}
          r={width * 0.4}
          fill={pieChartBackgroundColor}
        />
        <text
          x={centerX}
          y={centerY}
          textAnchor="middle"
          dominantBaseline="middle"
          style={{
            fontSize: textSize,
            fontWeight: 500,
            fontFamily: 'Inter',
          }}
        >
          {score ?? 0}
        </text>
        <Pie
          data={[
            {
              id: '1',
              name: 'L1',
              value: parseFloat(score?.toString()),
            },
            {
              id: '2',
              name: 'L2',
              value: 100 - parseFloat(score?.toString()),
            },
          ]}
          dataKey="value"
          innerRadius="80%"
          outerRadius="100%"
          fill="#E4E7EC"
          startAngle={90}
          endAngle={-270}
          paddingAngle={0}
          blendStroke
          isAnimationActive={false}
        >
          <Cell key="test" fill={customPieChartFillColor || '#7B73E4'} />
        </Pie>
      </PieChart>
    </ReactPDFChart>
  );
};

export default RiskScoreCircle;

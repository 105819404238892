import React, { useContext } from 'react';
import { View } from '@react-pdf/renderer';
import Footer from '../Footer';
import { PdfViewerContext } from 'app/context';
import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { PageBottomContainerProps } from './types';

function PageBottomContainer({
  children,
  additionalStyles,
  showFooter = true,
}: PageBottomContainerProps) {
  const hideElements = isParamContainsSage();
  const defaultStyles = tw('relative mt-auto bg-white');
  const { advisorDetails } = useContext(PdfViewerContext);
  return (
    <View fixed style={{ ...defaultStyles, ...additionalStyles }}>
      {children ?? <></>}
      {hideElements ? (
        <View style={tw('min-h-6')} />
      ) : (
        showFooter && <Footer advisorInfo={advisorDetails} />
      )}
    </View>
  );
}

export default PageBottomContainer;

export const getAllocationData = data => {
  if (Array.isArray(data) && data?.length > 0) {
    const filteredData = [...data]
      .sort((a, b) => a?.label?.localeCompare(b.label))
      .filter(item => item?.data !== 0 && item?.data > 0);
    return filteredData ?? [];
  } else return [];
};

export const getChartLabel = index => {
  if (index === 0) {
    return 'Current Portfolio';
  }
  if (index === 1) {
    return 'Recommended Portfolio';
  } else return 'Selected Model';
};

import React from 'react';
import { Image, Text, View } from '@react-pdf/renderer';

import currentPortfolioIcon from 'assets/currentPortfolioIcon.png';
import recommendedPortfolioIcon from 'assets/recommendedPortfolioIcon.png';
import { formatDigitUptoDecimalPlaces } from 'utils/helpers';
import { tw } from 'utils/tailwindSetup';
import { PortfolioItemProps } from './types';

function PortfolioItem({
  type,
  accountName,
  recommendedModelName,
  feesValue,
}: PortfolioItemProps) {
  const portfolioInfo = {
    currentPortfolio: {
      icon: currentPortfolioIcon,
      title: 'Current Portfolio',
      cardTitle: accountName ?? '',
    },
    recommendedPortfolio: {
      icon: recommendedPortfolioIcon,
      title: 'Recommended Portfolio',
      cardTitle: recommendedModelName ?? '',
    },
  };
  return (
    <>
      {type && (
        <View style={tw('w-full')}>
          <Text
            style={tw(
              'text-neutral500 font-inter text-[0.875rem] leading-[1.063rem] mb-2',
            )}
          >
            {portfolioInfo?.[type]?.title ?? ''}
          </Text>
          <View
            style={tw(
              'w-full border border-neutral200 rounded min-h-[11.5625rem]',
            )}
          >
            <View style={tw('flex flex-row gap-1 items-center p-4')}>
              <View>
                <Image
                  style={tw('w-4 h-4')}
                  src={portfolioInfo?.[type]?.icon ?? ''}
                />
              </View>
              <Text
                style={tw(
                  'font-inter text-black font-medium text-[0.5rem] leading-4',
                )}
              >
                {portfolioInfo?.[type]?.cardTitle ?? ''}
              </Text>
            </View>
            <View>
              {feesValue?.[type] !== undefined &&
                !isNaN(feesValue?.[type] ?? 0) && (
                  <Text
                    style={tw(
                      `w-full flex flex-row justify-center items-center mt-10 text-center font-inter font-semibold text-[2.25rem] leading-[2.723rem] ${type === 'currentPortfolio' ? 'text-[#457F8F]' : 'text-[#7B73E4]'}`,
                    )}
                  >
                    {formatDigitUptoDecimalPlaces(feesValue[type]! ?? 0)}%
                  </Text>
                )}
            </View>
          </View>
        </View>
      )}
    </>
  );
}

export default PortfolioItem;

import React from 'react';
import { riskCapacityColor, riskPreferenceColor } from './colors';

function RiskSliderGradient({ isCapacityLowerThanPreferenceValue = false }) {
  const defaultColor = isCapacityLowerThanPreferenceValue
    ? riskPreferenceColor
    : riskCapacityColor;

  const colorInverse = isCapacityLowerThanPreferenceValue
    ? riskCapacityColor
    : riskPreferenceColor;

  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%">
      <defs>
        <linearGradient id="g1" x1="1" x2="0">
          <stop offset=".001" stopColor={defaultColor} />
          <stop offset=".495" stopColor="#168B85" />
          <stop offset="1" stopColor={colorInverse} />
        </linearGradient>
      </defs>
      <rect width="100%" height="100%" fill="url(#g1)" />
    </svg>
  );
}

export default RiskSliderGradient;

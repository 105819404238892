import React from 'react';
import { Text, View } from '@react-pdf/renderer';

import { tw } from 'utils/tailwindSetup';
import { AllocationsInfoProps } from './types';

function AllocationsInfo({ data = [], colors = [] }: AllocationsInfoProps) {
  return (
    <View style={tw('w-full rounded-lg border border-neutral200')}>
      <View style={tw('p-4 flex flex-row border-b border-neutral200')}>
        <Text
          style={tw(
            'w-8/12 font-inter font-semibold text-default text-base leading-6',
          )}
        >
          Sectors
        </Text>
        <Text
          style={tw(
            'w-4/12 font-inter font-semibold text-default text-base leading-6',
          )}
        >
          Weight
        </Text>
      </View>
      <View>
        {Array.isArray(data) &&
          data.length > 0 &&
          data.map((stat, index) => (
            <View
              style={tw(
                `w-full p-2 flex flex-row justify-between ${index === data?.length - 1 ? 'border-b-0' : 'border-b'} border-neutral200`,
              )}
              key={stat?.label}
            >
              <View style={tw('w-8/12')}>
                <View style={tw('w-full flex flex-row items-center gap-2')}>
                  {colors && (
                    <View
                      style={tw(`h-3 w-3 bg-[${colors?.[index] ?? '#000'}]`)}
                    />
                  )}
                  <Text
                    style={tw(
                      'text-sm font-inter leading-[1.3125rem] text-default',
                    )}
                  >
                    {stat?.label ?? ''}
                  </Text>
                </View>
              </View>
              <View style={tw('w-4/12')}>
                <Text
                  style={tw(
                    'text-sm font-inter leading-[1.3125rem] text-default',
                  )}
                >
                  {stat?.data?.toFixed(2) ?? 0}%
                </Text>
              </View>
            </View>
          ))}
      </View>
    </View>
  );
}

export default AllocationsInfo;

import React from 'react';
import { CustomLabelProps } from './types';

function CustomLabel({
  viewBox = { cx: 0, cy: 0 },
  value = '',
  labelSize,
  labelFontColor,
  labelFontWeight,
  customLabelSpacing = 18,
}: CustomLabelProps) {
  let { cx, cy } = viewBox;
  const words = value.split(' ');
  if (words.length > 1) {
    cy = cy - 10;
  }

  return (
    <>
      {Array.isArray(words) &&
        words.length > 0 &&
        words.map((word: string, index: number) => (
          <text
            key={index}
            x={cx}
            y={cy + index * customLabelSpacing}
            fill={labelFontColor}
            dominantBaseline="middle"
            fontSize={labelSize}
            fontWeight={labelFontWeight}
            className="recharts-text recharts-label"
            textAnchor="middle"
            dy="0.25rem"
          >
            {word}
          </text>
        ))}
    </>
  );
}

export default CustomLabel;

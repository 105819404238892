import React from 'react';
import ProposalPage from 'app/components/ProposalPage';
import { Text, View } from '@react-pdf/renderer';
import ProjectedGrowth from 'app/components/ProjectedGrowth';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';

import { tw } from 'utils/tailwindSetup';
import { isParamContainsSage } from 'utils/helpers';
import { AnalyticsPotentialReturnProps } from './types';

function AnalyticsPotentialReturn({
  projectedGrowthData = [],
  advisorFee = [{ maxAnnualFees: 0, actualFees: 0 }],
  headerTitle = 'Potential Return',
  advisorFirmName = '',
}: AnalyticsPotentialReturnProps) {
  const isSageDocument = isParamContainsSage();
  const feesValue =
    Array.isArray(advisorFee) && advisorFee.length > 0
      ? advisorFee[0]?.actualFees
      : 0;

  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('w-[90%] mx-auto')}>
        <View style={tw('w-full h-[23.125rem]')}>
          {projectedGrowthData ? (
            <ProjectedGrowth
              data={projectedGrowthData}
              width={600}
              chartMargin={{
                top: 50,
              }}
              isPresentInPotentialReturns
            />
          ) : (
            <></>
          )}
        </View>
      </View>
      <Text
        style={tw(
          'w-full font-inter italic text-[0.5rem] leading-6 text-neutral600',
        )}
      >
        The figures shown reflect the deduction of the advisor's annual advisory
        fee, but may not include commissions, sales charges, custodial fees or
        platform fees, which, if included would result in lower values.
      </Text>
      <PageBottomContainer>
        <Disclosure
          text={[
            `${isSageDocument ? 'SAGE' : 'TIFIN'} aggregates Capital Market Assumptions (CMAs) reported by the top asset management institutions and uses Geometric Brownian Motion (GBM) to project Current and Recommended portfolio balances for 10 years. Geometric Brownian motion is used to describe random movements of financial assets over time where the size of the variations is proportional to the current value of the asset. GBM is the industry standard model used in finance to forecast security returns. The benefits of GBM over Monte Carlo (making standard industry assumptions such as normality and static volatility) is speed (runs much faster) and will return the same results every time due to forecasting every point on the distribution versus making a random distribution. Potential drawbacks of GBM (and Monte Carlo) is the assumption of constant volatility and normal distribution; however, over long enough time periods these potential drawbacks become mitigated.`,
            `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} aggregates Capital Market Assumptions (CMAs) from various large third-party asset managers and uses proprietary techniques to forecast security and portfolio-specific returns and volatility. `,
            `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} calculates the forward expected returns and volatility for the Current and Recommended Portfolios. For additional information on CMAs please see the Appendix. This results in ~70% of possible portfolio balances falling with the “Upper” and “Lower” bands; given the forecasted portfolio’s return and volatility.`,
            `Using geometric Brownian motion, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} projects the Current and Recommended portfolios’ balances for 10 years. The “Mean band” represents the expected return (50th percentile)of the respected portfolio (Current or Recommended); the “Lower band” represents the bottom 15th percentile of possible portfolio balances; and the “Upper band” represents the top 85th percentile of possible portfolio balances.`,
            `The annual investment advisory fee used in this report is ${feesValue ?? 0}%.`,
            `Important: The projections or the information generated by this simulation regarding the likelihood of various investment outcomes are hypothetical in nature, do not reflect actual investment results and are not guarantees of future results. Results may vary with each use and over time. Other investments not considered might have characteristics similar or superior to those analyzed in this report.`,
            `${advisorFirmName || 'The Advisory Firm'} does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. Actual trading results may be materially lower than those illustrated.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default AnalyticsPotentialReturn;

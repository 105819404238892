import React from 'react';
import ExpenseRatio from 'app/pages/ExpenseRatio';
import { CompareExpenseRatioProps } from './types';

function CompareExpenseRatio({
  currentPortfolioFeesValue = 0,
  recommendedPortfolioFeesValue = 0,
  headerTitle = 'Compare - Expense Ratio',
  accountName = 'Current Portfolio',
}: CompareExpenseRatioProps) {
  if (!currentPortfolioFeesValue) return <></>;

  return (
    <ExpenseRatio
      displayAllPortfolio
      feesValue={{
        currentPortfolio: currentPortfolioFeesValue ?? 0,
        recommendedPortfolio: recommendedPortfolioFeesValue ?? 0,
      }}
      accountName={accountName}
      pageDefaultHeader={headerTitle}
    />
  );
}

export default CompareExpenseRatio;

export const keyFactors = {
  title: 'Your portfolio was designed based upon the following key factors:',
  listOfFactors: [
    'The investment objective for each account.',
    'Your willingness and ability to tolerate risk in order to achieve those objectives.',
    'How much money you plan to invest.',
    'How long you plan to keep your money invested.',
    'Additional information provided by you (Please note that performance comparisons are based on historical data and may not be reflective of future results).',
  ],
};

export const delibration = {
  title: 'This Statement is designed to:',
  listOfStatements: [
    "Establish reasonable objectives and guidelines for the Client's investment as indicated to the Advisor.",
    "Present a comprehensive Client's Risk Profile, including both Risk Capacity and Risk Tolerance, as well as the risk in the investment portfolio(s).",
    "Summarize the Client's answers from the Risk Questionnaire that serve as the inputs for the Client Risk Profile.",
    "Summarize the Client's existing investment portfolio.",
    'Present the recommended investment portfolio that the Advisor has selected for this investment of the Client.',
    'Present the asset allocation, risk and performance simulation of the recommended investment portfolio.',
  ],
};

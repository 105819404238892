export const PAGE_CONTENT_LIST = {
  COVER: 'COVER',
  TABLE_OF_CONTENTS: 'TABLE_OF_CONTENTS',
  IPS: 'IPS',
  RISK_BAND: 'RISK_BAND',
  ASSESSMENT_INPUTS: 'ASSESSMENT_INPUTS',
  RECOMMENDED_PORTFOLIO_SNAPSHOT: 'RECOMMENDED_PORTFOLIO_SNAPSHOT',
  RECOMMENDED_PORTFOLIO_HOLDINGS: 'RECOMMENDED_PORTFOLIO_HOLDINGS',
  RECOMMENDED_PORTFOLIO_RISK_SCORE: 'RECOMMENDED_PORTFOLIO_RISK_SCORE',
  RECOMMENDED_PORTFOLIO_EXPENSE_RATIO: 'RECOMMENDED_PORTFOLIO_EXPENSE_RATIO',
  RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS:
    'RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS',
  CURRENT_PORTFOLIO: 'CURRENT_PORTFOLIO',
  RECOMMENDED_PORTFOLIO_THEMES: 'RECOMMENDED_PORTFOLIO_THEMES',
  CURRENT_PORTFOLIO_HOLDINGS: 'CURRENT_PORTFOLIO_HOLDINGS',
  CURRENT_PORTFOLIO_ALLOCATION_CHARTS: 'CURRENT_PORTFOLIO_ALLOCATION_CHARTS',
  COMPARE_RISK: 'COMPARE_RISK',
  COMPARE_EXPENSE_RATIO: 'COMPARE_EXPENSE_RATIO',
  COMPARE_HOLDINGS: 'COMPARE_HOLDINGS',
  ASSET_ALLOCATION: 'ASSET_ALLOCATION',
  REGION_ALLOCATION: 'REGION_ALLOCATION',
  HISTORICAL_PERFORMANCE: 'HISTORICAL_PERFORMANCE',
  RETURN_SIMULATION: 'RETURN_SIMULATION',
  COMPARE_HISTORICAL_RETURN_SIMULATION: 'COMPARE_HISTORICAL_RETURN_SIMULATION',
  COMPARE_RISK_AND_FEES: 'COMPARE_RISK_AND_FEES',
  ASSET_ALLOCATION_SNAPSHOT: 'ASSET_ALLOCATION_SNAPSHOT',
  SECTOR_ALLOCATION: 'SECTOR_ALLOCATION',
  HISTORICAL_RETURN: 'HISTORICAL_RETURN',
  POTENTIAL_RETURN: 'POTENTIAL_RETURN',
  POTENTIAL_RISK: 'POTENTIAL_RISK',
  DUTIES_AND_RESPONSIBILITIES: 'DUTIES_AND_RESPONSIBILITIES',
  DISCLOSURES: 'DISCLOSURES',
  ADVISOR_FEE_DISCLOSURE: 'ADVISOR_FEE_DISCLOSURE',
  WEALTH_DISCLOSURES: 'WEALTH_DISCLOSURES',
  RISK_DISCLOSURES: 'RISK_DISCLOSURES',
  APPENDIX: 'APPENDIX',
  APPENDIX_CMA: 'APPENDIX_CMA',
};

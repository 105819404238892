import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import RiskScoreCircle from 'app/components/RiskScoreCircle';
import { tw } from 'utils/tailwindSetup';
import { formatToMillions } from 'utils/helpers';
import { CompareHoldingsTableProps } from './types';

function CompareHoldingsTable({
  holdingsData,
  showTotal = true,
  showBorder = true,
  portfolioType = 'CURRENT_PORTFOLIO',
}: CompareHoldingsTableProps) {
  const rowsPerPage = 8;
  const tableHeader = ['TICKER', 'NAME', 'RISK SCORE', '$ VALUE', 'WEIGHT'];

  return (
    <View
      style={tw(
        `w-full ${showBorder ? 'border-l border-[#d7d7d7] pl-4' : 'border-l-0 border-transparent'}`,
      )}
    >
      <Text
        style={tw(
          'font-bold font-inter text-[1.17rem] leading-[1.755rem] my-4',
        )}
      >
        {portfolioType === 'CURRENT_PORTFOLIO'
          ? 'Current Holdings'
          : 'Recommended Holdings'}
      </Text>
      <View
        fixed
        style={tw(
          'w-full flex flex-row justify-between items-center border-t border-b border-darkGreen',
        )}
      >
        {tableHeader.map((header, index) => (
          <Text
            key={index}
            style={tw(
              `font-inter font-semibold text-xs text-darkGreen bg-white py-3`,
            )}
          >
            {header}
          </Text>
        ))}
      </View>

      <View style={tw('w-full')}>
        {Array.isArray(holdingsData) && holdingsData.length > 0 ? (
          holdingsData.map((data, index) => {
            const lastItem = index === holdingsData?.length - 1;
            const itemsPerPage = index > 0 && index % rowsPerPage === 0;
            const shouldDisplayBorder =
              (index + 1) % rowsPerPage !== 0 &&
              index + 1 !== holdingsData?.length;

            return (
              <>
                <View
                  style={tw(
                    `w-full py-3 flex flex-row justify-between items-center ${shouldDisplayBorder ? 'border-b border-[#d7d7d7]' : 'border-b-0'}`,
                  )}
                  key={data?.ticker ?? index}
                  break={itemsPerPage}
                >
                  <Text
                    style={tw(
                      'font-inter text-xs text-default leading-[1.125rem] w-[3.125rem]',
                    )}
                  >
                    {data?.ticker ?? ''}
                  </Text>
                  <Text
                    style={tw(
                      'font-inter text-xs text-default leading-[1.125rem] w-[4.75rem]',
                    )}
                  >
                    {data?.name ? data?.name : data?.ticker ?? '' ?? ''}
                  </Text>
                  <View
                    style={tw(
                      'font-inter text-xs text-default leading-[1.125rem] w-[3.125rem]',
                    )}
                  >
                    <RiskScoreCircle score={data?.riskScore ?? 0} />
                  </View>
                  <Text
                    style={tw(
                      'font-inter text-xs text-default leading-[1.125rem] w-[4.75rem] text-center',
                    )}
                  >
                    ${formatToMillions(+(data?.value ?? 0))}
                  </Text>
                  <Text
                    style={tw(
                      'font-inter text-xs text-default leading-[1.125rem] w-[3.125rem]',
                    )}
                  >
                    {+data?.weight?.toFixed(2) ?? 0}%
                  </Text>
                </View>
                {lastItem && showTotal ? (
                  <View
                    style={tw(
                      'flex flex-row justify-between border-t border-[#033e3e] py-[0.625rem]',
                    )}
                  >
                    <Text
                      style={tw(
                        'font-inter text-xs font-bold leading-[1.125rem] text-[#033e3e]',
                      )}
                    >
                      Total
                    </Text>
                    <Text
                      style={tw(
                        'font-inter text-xs font-bold leading-[1.125rem] text-[#033e3e]',
                      )}
                    >
                      100%
                    </Text>
                  </View>
                ) : (
                  <></>
                )}
              </>
            );
          })
        ) : (
          <></>
        )}
      </View>
    </View>
  );
}

export default CompareHoldingsTable;

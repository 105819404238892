import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';

import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import RiskBand from 'app/components/RiskBand';
import PortfolioScoreBasedOnType from './PortfolioScoreBasedOnType';

import { tw } from 'utils/tailwindSetup';
import { RiskScoreProps } from './types';
import { isParamContainsSage } from 'utils/helpers';

function RiskScore({
  currentPortfolioData,
  recommendedPortfolioData,
  portfolioType = 'RECOMMENDED',
}: RiskScoreProps) {
  const isSageDocument = isParamContainsSage();
  const dataBasedOnPortfolioType = useMemo(() => {
    if (currentPortfolioData && portfolioType === 'CURRENT') {
      return {
        riskScore: currentPortfolioData?.riskBand?.riskScore ?? 0,
        riskData: recommendedPortfolioData?.riskBand ?? 0,
        lossGain: currentPortfolioData?.lossGain ?? {},
      };
    }
    return {
      riskScore: recommendedPortfolioData?.riskBand?.riskScore ?? 0,
      riskData: recommendedPortfolioData?.riskBand ?? 0,
      lossGain: recommendedPortfolioData?.lossGain ?? {},
    };
  }, [currentPortfolioData, portfolioType, recommendedPortfolioData]);

  const { riskCapacity, riskPreference } =
    dataBasedOnPortfolioType?.riskData ?? {
      riskCapacity: 0,
      riskPreference: 0,
    };

  const isWithinRange = useMemo(() => {
    if (
      (riskCapacity <= recommendedPortfolioData?.riskBand?.riskScore &&
        dataBasedOnPortfolioType?.riskScore <= riskPreference) ||
      (riskPreference <= dataBasedOnPortfolioType?.riskScore &&
        dataBasedOnPortfolioType?.riskScore <= riskCapacity)
    ) {
      return 'within';
    }

    if (
      dataBasedOnPortfolioType?.riskScore <= riskCapacity &&
      dataBasedOnPortfolioType?.riskScore <= riskPreference
    ) {
      return 'below';
    }

    return 'above';
  }, [
    dataBasedOnPortfolioType,
    recommendedPortfolioData,
    riskCapacity,
    riskPreference,
  ]);

  const riskScoreInfo = (
    <>
      {riskCapacity && riskPreference ? (
        <Text
          style={tw('font-inter text-default text-xs leading-4 text-center')}
        >
          Your portfolio risk score is{' '}
          <Text
            style={tw(
              'font-inter font-semibold text-default text-xs leading-4 text-center',
            )}
          >
            {dataBasedOnPortfolioType?.riskScore ?? 0}{' '}
          </Text>
          and lies {isWithinRange ?? 'within'} your risk band
        </Text>
      ) : (
        <Text
          style={tw('font-inter text-default text-xs leading-4 text-center')}
        >
          Your portfolio risk score is{' '}
          <Text
            style={tw(
              'font-inter font-semibold text-default text-xs leading-4 text-center',
            )}
          >
            {dataBasedOnPortfolioType?.riskScore ?? 0}{' '}
          </Text>
        </Text>
      )}
    </>
  );

  return (
    <ProposalPage
      headerTitle={
        portfolioType === 'RECOMMENDED'
          ? 'Recommendation Risk'
          : 'Current Portfolio'
      }
    >
      <View>
        <Text
          style={tw(
            'font-inter font-medium text-sm leading-[1.059rem] text-default mb-2',
          )}
        >
          Risk Score
        </Text>

        <Text
          style={tw(
            'font-inter font-medium text-xs leading-4 text-default min-h-4',
          )}
        >
          Your {portfolioType === 'CURRENT' ? 'current' : 'recommended'}{' '}
          portfolio's risk score and possibles gains or possible loss.
        </Text>
        <View>
          <View style={tw('mb-6')}>
            <PortfolioScoreBasedOnType
              riskScore={dataBasedOnPortfolioType?.riskScore}
              lossGain={dataBasedOnPortfolioType?.lossGain}
              portfolioType={portfolioType}
              isPortfolioDataExist={
                recommendedPortfolioData &&
                Object.keys(recommendedPortfolioData).length > 0
              }
            />
          </View>
        </View>
      </View>
      {dataBasedOnPortfolioType?.riskData?.riskCapacity &&
        dataBasedOnPortfolioType?.riskData?.riskPreference && (
          <View
            style={tw(
              'w-full flex flex-col items-center border border-neutral200 rounded p-8 pb-0',
            )}
          >
            {riskScoreInfo ?? ''}
            <View style={tw('w-full h-[15rem] flex flex-row items-center')}>
              <RiskBand
                riskData={{
                  riskCapacity:
                    dataBasedOnPortfolioType?.riskData?.riskCapacity,
                  riskPreference:
                    dataBasedOnPortfolioType?.riskData?.riskPreference,
                }}
                riskScores={[
                  {
                    value: dataBasedOnPortfolioType?.riskScore,
                  },
                ]}
              />
            </View>
          </View>
        )}
      <PageBottomContainer>
        <Disclosure
          text={[
            `Possible losses are represented as a 99% Value-at-Risk metric, meaning your current portfolio value could lose up to this amount or more in a given year (Portfolio Value x Risk Score %). Possible gains represent the potential portfolio value gains within a given year using the same distribution as potential losses with a 1% chance. Both gains and losses are calculated using a normal distribution and are hypothetical, representing no guarantee for future loss or future gains. The figures shown reflect the deduction of the advisor'sannual advisory fee, but may not include commissions, sales charges, custodial fees or platform fees, which, if included would result in lower values. The returns shown are net of the advisor's annual advisory fee.`,
            `${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} calculates the estimated forward Risk Score of individual securities and portfolios using a parametric Value at Risk (VaR)calculation at the 99% level for a one-year period. ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} estimates the forward expected return and volatility of the security or portfolio using Capital Market Assumptions. ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} uses the estimated forward return and volatility of the security or portfolio to find the possible gain or loss of the client's account balance at the 1% level over a one-year period.`,
            `The information, data, analysis, and opinions contained in this report include the confidential and proprietary information of ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} ; may include or be derived from account information provided by you or your financial representative which cannot be verified by ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'}. This information may not be copied or redistributed; does not constitute investment advice and is provided solely for informational purposes only. This material does not constitute an offer to buy or sell a security. Product suitability must be independently determined for each individual investor. Except as otherwise provided by law, ${isSageDocument ? 'SAGE' : 'TIFIN Wealth'} shall not be responsible for any trading decisions, damages or other losses resulting from the use of this information, data, analysis or opinion. This report is supplemental sales literature. If applicable, this report should be preceded or accompanied by a prospectus, or equivalent, and disclosure statement.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default RiskScore;

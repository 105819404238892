import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import { Cell, Pie, PieChart } from 'recharts';
import ReactPDFChart from 'react-pdf-charts';

import LossGainBand from 'app/components/LossGainBand';

import { tw } from 'utils/tailwindSetup';
import { PortfolioScoreBasedOnTypeProps } from './types';

function PortfolioScoreBasedOnType({
  riskScore,
  lossGain,
  isPortfolioDataExist = false,
  portfolioType = 'RECOMMENDED',
}: PortfolioScoreBasedOnTypeProps) {
  return (
    <View
      style={tw(
        'w-full flex flex-row items-center border border-neutral200 rounded-lg py-5 mt-2 h-[12.5rem]',
      )}
    >
      <View style={tw('flex flex-col items-center justify-center w-5/12')}>
        {isPortfolioDataExist ? (
          riskScore && (
            <ReactPDFChart>
              <PieChart width={100} height={100}>
                <text
                  x={50}
                  y={52}
                  textAnchor="middle"
                  dominantBaseline="middle"
                  style={{
                    fontSize: 20,
                    fontWeight: '500',
                    color: '#101828',
                  }}
                >
                  {riskScore ?? 0}
                </text>
                <Pie
                  data={[
                    {
                      id: '1',
                      name: 'L1',
                      value: riskScore,
                    },
                    {
                      id: '2',
                      name: 'L2',
                      value: 100 - riskScore,
                    },
                  ]}
                  dataKey="value"
                  innerRadius={'70%'}
                  outerRadius="80%"
                  fill="#E4E7EC"
                  startAngle={90}
                  endAngle={-270}
                  paddingAngle={0}
                  blendStroke
                  isAnimationActive={false}
                >
                  <Cell key="test" fill="#7B73E4" />
                </Pie>
              </PieChart>
            </ReactPDFChart>
          )
        ) : (
          <></>
        )}
        <View style={tw('flex flex-col justify-center items-center')}>
          <Text
            style={tw(
              'font-inter font-medium text-[0.625rem] leading-5 text-[#494F5B] mt-[0.8125rem]',
            )}
          >
            Portfolio Risk Score
          </Text>
          <Text
            style={tw(
              'font-inter font-medium text-[0.438rem] leading-5 text-[#494F5B] py-0.5 px-[2.1875rem] mt-0.5 text-center min-h-[1.3075rem]',
            )}
          >
            A portfolio risk score of {riskScore ?? 0} means that{' '}
            {riskScore ?? 0}% of your{' '}
            {portfolioType === 'CURRENT' ? 'current' : 'recommended'} portfolio
            value could be lost.
          </Text>
        </View>
      </View>
      <View style={tw('w-1/12')}>
        <View
          style={tw(
            'border-[0.8px] border-neutral200 h-[7.5rem] w-[1px] bg-neutral200',
          )}
        />
      </View>
      <View style={tw('w-1/2')}>
        <View
          style={tw(
            'h-full flex flex-col items-center justify-center p-4 gap-3',
          )}
        >
          <LossGainBand lossGain={lossGain} />
        </View>
      </View>
    </View>
  );
}

export default PortfolioScoreBasedOnType;

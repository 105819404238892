import React from 'react';
import ProposalPage from 'app/components/ProposalPage';
import AssessmentsQnA from 'app/components/AssessmentsQ&A';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { AssessmentsProps } from './types';
import { isParamContainsSage } from 'utils/helpers';

function Assessments({ assessmentsData }: AssessmentsProps) {
  return (
    <>
      {assessmentsData.map(type => {
        return Array.isArray(type.data) && type.data.length > 0 ? (
          <ProposalPage
            headerTitle={
              isParamContainsSage() ? 'Assessment Inputs' : type.title
            }
          >
            {type?.data?.map((el, idx) => (
              <AssessmentsQnA
                question={el?.question}
                answer={el?.answer as string}
                index={idx}
                noOfQuestions={type?.data?.length}
              />
            ))}
            <PageBottomContainer />
          </ProposalPage>
        ) : (
          <></>
        );
      })}
    </>
  );
}

export default Assessments;

export const populateRiskScoreData = (data: any) => {
  if (data && Object.keys(data).length) {
    const { riskCapacity, riskPreference } = data?.riskDetails;
    return {
      riskBand: data?.riskDetails
        ? {
            color: '#41926E',
            options: {
              riskCapacity: riskCapacity,
              riskPreference: riskPreference,
            },
          }
        : null,
      riskScore: data?.riskScore ?? 0,
      lossGain: data?.lossGain,
    };
  }
  return null;
};

import { createContext } from 'react';
import { PdfContextViewerProps } from './types';

export const PdfViewerContext = createContext<PdfContextViewerProps>({
  advisorDetails: {
    email: '',
    name: '',
    firmLogo: '',
    firmName: '',
  },
  clientDetails: {
    email: '',
    name: '',
  },
});

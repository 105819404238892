import { PAGE_CONTENT_LIST } from 'utils/constants/pageContentList';

const checkAssetAllocationData = proposalData =>
  !!proposalData?.currentPortfolioAllocationsData?.assetAllocation?.length &&
  !!proposalData?.recommendationDetails?.assetAllocation?.length;

const checkSectorAllocationData = proposalData =>
  !!proposalData?.currentPortfolioAllocationsData?.sectorAllocation?.length &&
  !!proposalData?.recommendationDetails?.sectorAllocation?.length;

const checkRegionAllocationData = proposalData =>
  !!proposalData?.currentPortfolioAllocationsData?.regionAllocation?.length &&
  !!proposalData?.recommendationDetails?.regionAllocation?.length;

const checkHistoricalPerformanceData = proposalData =>
  !!proposalData?.historicalReturns?.returns &&
  !!proposalData?.historicalReturns?.commonReturns?.period &&
  showCompareSection(proposalData);

const checkReturnSimulationData = proposalData =>
  !!proposalData?.currentPortfolio?.projectedGrowth?.length &&
  !!proposalData?.recommendationDetails?.projectedGrowth?.length;

const checkRecommendedPortfolioSnapshotData = proposalData =>
  !!proposalData?.recommendationDetails?.assetAllocation?.length &&
  proposalData?.recommendationDetails?.assetAllocation?.length > 0 &&
  !!proposalData?.recommendationDetails?.baseModel;

const checkRecommendedPortfolioHoldings = proposalData =>
  !!proposalData?.recommendationDetails?.holdings;

const checkRecommendedPortfolioRiskScoreData = proposalData =>
  !!proposalData?.recommendationDetails;
const checkRecommendedPortfolioAllocationChartsData = proposalData =>
  !!proposalData?.recommendationDetails?.regionAllocation?.length &&
  !!proposalData?.recommendationDetails?.sectorAllocation?.length &&
  !!proposalData?.recommendationDetails?.assetAllocation?.length;
const checkCurrentPortfolioAllocationCharts = proposalData =>
  !!proposalData?.currentPortfolioAllocationsData?.regionAllocation?.length &&
  !!proposalData?.currentPortfolioAllocationsData?.sectorAllocation?.length &&
  !!proposalData?.currentPortfolioAllocationsData?.assetAllocation?.length;
const checkHistoricalReturnData = proposalData =>
  !!proposalData?.historicalReturns?.returns?.chartsData?.length &&
  !!proposalData?.historicalReturns?.commonReturns?.period;
const checkCompareRiskData = proposalData =>
  (!!proposalData?.compareRiskData?.recommendedPortfolioData?.riskDetails ||
    !!proposalData?.compareRiskData?.recommendedPortfolioData?.lossGain) &&
  !!proposalData?.compareRiskData?.currentPortfolioData?.lossGain;
const checkCompareHoldingsData = proposalData =>
  !!proposalData?.compareHoldingsData?.currentPortfolioHoldings &&
  !!proposalData?.compareHoldingsData?.recommendedPortfolioHoldings &&
  proposalData?.compareHoldingsData?.currentPortfolioHoldings?.length > 0 &&
  proposalData?.compareHoldingsData?.recommendedPortfolioHoldings?.length > 0;

const checkPotentialRiskData = proposalData => {
  const volatilityAndDrawdown = [
    {
      currentPortfolio: +(
        proposalData?.riskStatisticsData?.currentPortfolio?.volatility?.toFixed(
          2,
        ) ?? 0
      ),
      recommendedPortfolio: +(
        proposalData?.riskStatisticsData?.recommendedPortfolio?.volatility?.toFixed(
          2,
        ) ?? 0
      ),
    },
    {
      currentPortfolio: +(
        proposalData?.riskStatisticsData?.currentPortfolio?.drawdown?.toFixed(
          2,
        ) ?? 0
      ),
      recommendedPortfolio: +(
        proposalData?.riskStatisticsData?.recommendedPortfolio?.drawdown?.toFixed(
          2,
        ) ?? 0
      ),
    },
  ];

  const upAndDownCapture = [
    {
      currentPortfolio: +(
        proposalData?.riskStatisticsData?.currentPortfolio?.upsideDownCapture?.upsideCapture?.toFixed(
          2,
        ) ?? 0
      ),
      recommendedPortfolio: +(
        proposalData?.riskStatisticsData?.recommendedPortfolio?.upsideDownCapture?.upsideCapture?.toFixed(
          2,
        ) ?? 0
      ),
    },
    {
      currentPortfolio: +(
        proposalData?.riskStatisticsData?.currentPortfolio?.upsideDownCapture?.downsideCapture?.toFixed(
          2,
        ) ?? 0
      ),
      recommendedPortfolio: +(
        proposalData?.riskStatisticsData?.recommendedPortfolio?.upsideDownCapture?.downsideCapture?.toFixed(
          2,
        ) ?? 0
      ),
    },
  ];

  return (
    volatilityAndDrawdown.every(
      item => !item.currentPortfolio && !item.recommendedPortfolio,
    ) ||
    upAndDownCapture.every(
      item => !item.currentPortfolio && !item.recommendedPortfolio,
    )
  );
};

const showCompareSection = proposalData =>
  !!proposalData?.currentPortfolio &&
  !!Object.keys(proposalData?.currentPortfolio)?.length &&
  !!proposalData?.recommendationDetails &&
  !!Object.keys(proposalData?.recommendationDetails)?.length;

export const dataChecks = proposalData => {
  return {
    [PAGE_CONTENT_LIST.COVER]: true,
    [PAGE_CONTENT_LIST.TABLE_OF_CONTENTS]: true,
    [PAGE_CONTENT_LIST.IPS]: true,
    [PAGE_CONTENT_LIST.RISK_BAND]:
      !!proposalData?.riskDetails &&
      Object.keys(proposalData?.riskDetails)?.length > 0 &&
      (!!proposalData?.riskDetails?.riskCapacity ||
        !!proposalData?.riskDetails?.riskPreference),
    [PAGE_CONTENT_LIST.COMPARE_RISK_AND_FEES]:
      showCompareSection(proposalData) &&
      !!proposalData?.compareRiskDataForRiskFee,
    [PAGE_CONTENT_LIST.ASSET_ALLOCATION]:
      checkAssetAllocationData(proposalData),
    [PAGE_CONTENT_LIST.ASSET_ALLOCATION_SNAPSHOT]:
      !!proposalData?.isWealthDocument ?? false,
    [PAGE_CONTENT_LIST.SECTOR_ALLOCATION]:
      checkSectorAllocationData(proposalData),
    [PAGE_CONTENT_LIST.REGION_ALLOCATION]:
      checkRegionAllocationData(proposalData),
    [PAGE_CONTENT_LIST.HISTORICAL_PERFORMANCE]:
      checkHistoricalPerformanceData(proposalData),
    [PAGE_CONTENT_LIST.RETURN_SIMULATION]:
      checkReturnSimulationData(proposalData),
    [PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_SNAPSHOT]:
      checkRecommendedPortfolioSnapshotData(proposalData),
    [PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_HOLDINGS]:
      checkRecommendedPortfolioHoldings(proposalData),
    [PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_RISK_SCORE]:
      checkRecommendedPortfolioRiskScoreData(proposalData),
    [PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_EXPENSE_RATIO]:
      !!proposalData?.recommendationDetails?.fees,
    [PAGE_CONTENT_LIST.RECOMMENDED_PORTFOLIO_ALLOCATION_CHARTS]:
      checkRecommendedPortfolioAllocationChartsData(proposalData),
    [PAGE_CONTENT_LIST.CURRENT_PORTFOLIO]:
      !!proposalData?.currentPortfolio?.holdings &&
      proposalData?.currentPortfolio?.holdings?.length &&
      checkCurrentPortfolioAllocationCharts(proposalData),
    [PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_HOLDINGS]:
      !!proposalData?.currentPortfolio?.holdings &&
      proposalData?.currentPortfolio?.holdings?.length > 0,
    [PAGE_CONTENT_LIST.CURRENT_PORTFOLIO_ALLOCATION_CHARTS]:
      checkCurrentPortfolioAllocationCharts(proposalData),
    [PAGE_CONTENT_LIST.HISTORICAL_RETURN]:
      checkHistoricalReturnData(proposalData),
    [PAGE_CONTENT_LIST.POTENTIAL_RETURN]:
      !!proposalData?.recommendationDetails?.projectedGrowth?.length &&
      proposalData?.recommendationDetails?.projectedGrowth?.every(
        item => item?.lowerBand && item?.upperBand && item?.meanBand,
      ),
    [PAGE_CONTENT_LIST.POTENTIAL_RISK]: !checkPotentialRiskData(proposalData),
    [PAGE_CONTENT_LIST.COMPARE_HISTORICAL_RETURN_SIMULATION]:
      showCompareSection(proposalData) &&
      proposalData?.historicalReturnsSimulationPeriods?.tableData?.length > 0,
    [PAGE_CONTENT_LIST.COMPARE_RISK]:
      showCompareSection(proposalData) && checkCompareRiskData(proposalData),
    [PAGE_CONTENT_LIST.COMPARE_EXPENSE_RATIO]: showCompareSection(proposalData),
    [PAGE_CONTENT_LIST.COMPARE_HOLDINGS]:
      showCompareSection(proposalData) &&
      checkCompareHoldingsData(proposalData),
    [PAGE_CONTENT_LIST.ASSESSMENT_INPUTS]:
      !!proposalData?.assessmentSubmissions?.length &&
      Array.isArray(proposalData?.assessmentSubmissions) &&
      proposalData?.assessmentSubmissions?.length > 0,
    [PAGE_CONTENT_LIST.DUTIES_AND_RESPONSIBILITIES]: true,
    [PAGE_CONTENT_LIST.DISCLOSURES]:
      !!proposalData?.additionalDetails?.disclosure,
    [PAGE_CONTENT_LIST.ADVISOR_FEE_DISCLOSURE]: true,
    [PAGE_CONTENT_LIST.WEALTH_DISCLOSURES]:
      !!proposalData?.isWealthDocument ?? false,
    [PAGE_CONTENT_LIST.RISK_DISCLOSURES]: true,
    [PAGE_CONTENT_LIST.APPENDIX]: true,
    [PAGE_CONTENT_LIST.APPENDIX_CMA]: true,
  };
};

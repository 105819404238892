export const getCoverTitle = (contentType = '') => {
  switch (contentType) {
    case 'IPS':
      return 'Investment Policy Statement for';
    case 'IPS_SNAPSHOT':
      return 'Investment Policy Statement';
    case 'CURRENT_PORTFOLIO':
      return 'Current Portfolio Snapshot for';
    case 'GENERAL_REPORT':
      return 'Investment Report for';
    case 'PERSONALITY_DRIVER':
      return 'Financial Personality of';
    case 'PLAN':
      return 'My Plans';
    default:
      return 'Portfolio Summary for';
  }
};

export const trimString = (str: string, numChars: number) => {
  if (str.length <= numChars) {
    return str;
  }
  return str.substring(0, numChars) + '...';
};

export const trimClientName = (str: string, numChars: number) => {
  let result = '';
  const splitString = str.split(' ') ?? [];

  for (const item of splitString) {
    if (result.length + item.length + 1 > numChars) {
      result = result.trim() + '...';
      break;
    }
    result += item + ' ';
  }
  return result.trim();
};

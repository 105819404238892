import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';
import { filterData, getStartEndDates, portfolioTableRow } from './utils';
import { tw } from 'utils/tailwindSetup';
import { HistoricalReturnSimulationTableProps } from './types';

function HistoricalReturnSimulationTable({
  tableData = [],
  difference,
  benchmarkName = '',
}: HistoricalReturnSimulationTableProps) {
  const transformedTableData = useMemo(() => {
    return Array.isArray(tableData) && tableData.length > 0
      ? tableData.map(item => {
          let dates: { startDate: string; endDate: any } | {} = {};
          if (item.period === 'max_date') {
            dates = getStartEndDates(tableData) || {};
          }
          return {
            ...item,
            ...dates,
          };
        })
      : [];
  }, [tableData]);

  const isTableDataExists =
    Array.isArray(transformedTableData) && transformedTableData.length > 0;

  const tableHeader = useMemo(() => {
    if (!isTableDataExists) return [];

    return [
      {
        label: 'Models',
        type: 'MODELS',
        valueKey: 'models',
        fixedWidth: true,
      },
      ...transformedTableData.map(item => ({
        label: item?.label,
        type: 'PERCENTAGE',
        valueKey: item?.label,
        fixedWidth: false,
      })),
    ];
  }, [transformedTableData, isTableDataExists]);

  return (
    <View style={tw('w-full min-h-[500px] flex flex-col items-start')}>
      <Text style={tw('w-full font-inter text-xs text-neutral600 leading-4')}>
        Returns represent hypothetical performance of $100 over the below time
        periods.
      </Text>

      <View
        style={tw('w-full mt-4 h-[250px] rounded-md border border-neutral200')}
      >
        <Text style={tw('font-inter text-[13.415px] font-medium p-[10.732px]')}>
          Historical Return Simulation Periods
        </Text>
        <View style={tw('flex flex-col')}>
          <View
            style={tw(
              'flex flex-row items-center border-b border-t border-neutral50 gap-3 px-3 h-10 bg-neutral50',
            )}
          >
            {tableHeader.map((header, index) => (
              <Text
                key={index}
                style={tw(
                  `font-inter text-xs font-medium leading-[1.125rem] text-default ${
                    header?.type === 'MODELS' ? 'w-[120px]' : 'flex-1'
                  }`,
                )}
              >
                {header?.label ?? ''}
              </Text>
            ))}
          </View>
          {portfolioTableRow(benchmarkName ?? '').map(portfolio => (
            <View
              style={tw(
                `w-full flex flex-row gap-3 p-3 h-10 border-b ${portfolio?.key === 'recommendedPercentage' ? 'border-b-0' : 'border-neutral200'}`,
              )}
              key={portfolio.key}
            >
              <Text
                style={tw(
                  'font-inter text-[9.391px] leading-4 w-[120px] font-medium',
                )}
              >
                {portfolio?.label ?? ''}
              </Text>
              {Array.isArray(transformedTableData) &&
              transformedTableData.length > 0 ? (
                transformedTableData.map((item, index) => {
                  const portfolioPercentage = filterData(
                    item?.period,
                    difference ?? 0,
                  )
                    ? item?.[portfolio.key] ?? 0
                    : 0;

                  return (
                    <View key={index} style={tw('flex-1')}>
                      <Text
                        style={tw(
                          'font-medium text-xs leading-[1.125rem] text-neutral400',
                        )}
                      >
                        {portfolioPercentage !== 0 ? (
                          <Text
                            style={tw(
                              `${
                                portfolioPercentage > 0
                                  ? 'text-[#00B186]'
                                  : 'text-[#FA2238]'
                              }`,
                            )}
                          >
                            {portfolioPercentage?.toFixed(2)}%
                          </Text>
                        ) : (
                          '--'
                        )}
                      </Text>
                    </View>
                  );
                })
              ) : (
                <></>
              )}
            </View>
          ))}
        </View>
      </View>
    </View>
  );
}

export default HistoricalReturnSimulationTable;

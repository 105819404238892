import React from 'react';
import ProposalPage from 'app/components/ProposalPage';
import { Text } from '@react-pdf/renderer';
import CompareAllocations from 'app/components/CompareAllocations';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';

import { tw } from 'utils/tailwindSetup';
import { CompareAssetAllocationsProps } from './types';

function CompareAssetAllocation({
  headerTitle = 'Compare - Asset Allocation',
  compareData = [],
  isAssetAllocationSnapshotPage = false,
}: CompareAssetAllocationsProps) {
  return (
    <>
      {Array.isArray(compareData) && compareData.length >= 2 ? (
        <ProposalPage headerTitle={headerTitle}>
          <Text
            style={tw(
              'font-inter font-medium text-default text-sm leading-[1.059rem] pb-2',
            )}
          >
            {isAssetAllocationSnapshotPage ? 'Asset Class' : 'Asset Allocation'}
          </Text>
          <Text
            style={tw('font-inter text-xs leading-[1.375rem] text-neutral600')}
          >
            Comparing asset allocations across the major Asset Classes to see
            how your holdings and its underlying exposures will change with the
            recommendation.
          </Text>
          <CompareAllocations
            compareData={compareData}
            allocationType="ASSET_ALLOCATION"
          />
          <PageBottomContainer>
            <Disclosure
              minHeight={false}
              text={[
                `Data sourced from Morningstar, Inc.`,
                `*Other is a catchall asset class that includes assets like forward contracts, futures contracts, options, commodities, structured products, and other alternative investments.`,
              ]}
            />
          </PageBottomContainer>
        </ProposalPage>
      ) : (
        <></>
      )}
    </>
  );
}

export default CompareAssetAllocation;

import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';
import PortfolioItem from './PortfolioItem';
import { tw } from 'utils/tailwindSetup';
import { BaseProps, PortfolioItemProps } from './types';

function ExpenseRatio({
  type = 'currentPortfolio',
  feesValue = { currentPortfolio: 0, recommendedPortfolio: 0 },
  pageDefaultHeader = 'Expense Ratio',
  accountName = 'Current Portfolio',
  displayAllPortfolio = true,
  recommendedModelName = 'Recommended Portfolio',
}: PortfolioItemProps & BaseProps) {
  const commonProps = { accountName, recommendedModelName, feesValue };
  return (
    <ProposalPage headerTitle={pageDefaultHeader}>
      <Text
        style={tw(
          'font-inter text-default font-medium text-[0.875rem] leading-[1.059rem] pb-2',
        )}
      >
        Net Expense Ratio
      </Text>
      <Text style={tw('font-inter text-xs leading-5 text-neutral600')}>
        Reflects the portfolio level Net Expense Ratio during a particular
        fiscal year based on the weighted average of the underlying funds held
        in the portfolio.{' '}
      </Text>
      <View style={tw('pt-5')}>
        {displayAllPortfolio ? (
          <View style={tw('w-full flex flex-row justify-between gap-4')}>
            <PortfolioItem type="currentPortfolio" {...commonProps} />
            <PortfolioItem type="recommendedPortfolio" {...commonProps} />
          </View>
        ) : (
          <PortfolioItem type={type} {...commonProps} />
        )}
      </View>
      <PageBottomContainer>
        <Disclosure
          text={[
            `Data sourced from Morningstar, Inc.`,
            `The Expense Ratio at the fund level is the Prospectus Net Expense Ratio, conditional if the latest prospectus date is less than one year old. Prospectus Net Expense Ratio, as defined by Morningstar, is the percentage of fund assets, net of reimbursements, used to pay for operating expenses and management fees, including 12b-1 fees, administrative fees, and all other asset-based costs incurred by the fund, except brokerage costs. Fund expenses are reflected in the fund's NAV. Sales charges are not included in the expense ratio. The Prospectus Net Expense Ratio is collected annually from a fund's prospectus which can be obtained by your financial professional. If the prospectus is not available or greater than 1 year old, the Annual Reported Net Expense Ratio is used.`,
            `Note: The Expense Ratio is not inclusive of the Advisor’s fees or platform fees.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default ExpenseRatio;

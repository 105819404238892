export const pieChartColor = title => {
  return [
    {
      label: 'Developed Markets',
      color: '#00B186',
    },
    {
      label: 'Others',
      color: title === 'regionAllocation' ? '#7B73E4' : '#462A27',
    },
    {
      label: 'Emerging Markets',
      color: '#FFA32C',
    },
    {
      label: 'Information Technology',
      color: '#19D6DB',
    },
    {
      label: 'Financials',
      color: '#CE93D8',
    },
    {
      label: 'Healthcare',
      color: '#2196F3',
    },
    {
      label: 'Consumer Discretionary',
      color: '#F21D6B',
    },
    {
      label: 'Communication Services',
      color: '#311B92',
    },
    {
      label: 'Industrials',
      color: '#457F8F',
    },
    {
      label: 'Consumer Staples',
      color: '#FDD835',
    },
    {
      label: 'Materials',
      color: '#FFA32C',
    },
    {
      label: 'Energy',
      color: '#27525E',
    },
    {
      label: 'Sector Real Estate',
      color: '#00B186',
    },
    {
      label: 'Utilities',
      color: '#7B73E4',
    },
    {
      label: 'Equities',
      color: '#311B92',
    },
    {
      label: 'Bonds',
      color: '#457F8F',
    },
    {
      label: 'Derivatives',
      color: '#00B186',
    },
    { label: 'Cash', color: '#FDD835' },
    { label: 'Currencies', color: '#7B73E4' },
    { label: 'Other', color: '#27525E' },
    { label: 'Commodities', color: '#FFA32C' },
  ];
};

export const PIE_CHART_COLORS_FOR_ALLOCATIONS = [
  '#7B73E4',
  '#FFA32C',
  '#00B186',
  '#2B5C69',
  '#FDD835',
  '#035446',
  '#311B92',
  '#D81B60',
  '#2196F3',
  '#CE93D8',
  '#00BCD4',
  '#462A27',
  '#FA4D56',
];

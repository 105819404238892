import React from 'react';
import { Text, View } from '@react-pdf/renderer';
import ProposalPage from 'app/components/ProposalPage';
import { tw } from 'utils/tailwindSetup';
import PageBottomContainer from 'app/components/PageBottomContainer';
import { CONTENT } from './constants';

interface RiskDisclosureProps {
  headerTitle?: string;
}

function RiskDisclosures({
  headerTitle = 'Risks and Disclosures',
}: RiskDisclosureProps) {
  return (
    <ProposalPage headerTitle={headerTitle}>
      <View style={tw('mb-[3.125rem]')}>
        {Array.isArray(CONTENT) &&
          CONTENT.map(el => (
            <Text
              style={tw(
                'font-normal text-[0.6875rem] text-neutral600 font-inter mb-5',
              )}
            >
              {el}
            </Text>
          ))}
        <Text
          style={tw(
            'font-bold text-[0.6875rem] text-neutral600 font-inter mb-5',
          )}
        >
          Investors should consider the investment objectives, risks, charges
          and expenses of the investment company carefully before investing. The
          prospectus and, if available, the summary prospectus contain this and
          other important information about the investment company. Request a
          prospectus from the offering institution or your financial
          representative. Read carefully before investing.
        </Text>
      </View>
      <PageBottomContainer></PageBottomContainer>
    </ProposalPage>
  );
}

export default RiskDisclosures;

import React from 'react';
import ProposalPage from 'app/components/ProposalPage';
import { Text, View } from '@react-pdf/renderer';
import CompareAllocations from 'app/components/CompareAllocations';
import PageBottomContainer from 'app/components/PageBottomContainer';
import Disclosure from 'app/components/Disclosure';

import { tw } from 'utils/tailwindSetup';
import { CompareRegionAllocationsProps } from './types';

function CompareRegionAllocation({
  headerTitle = 'Compare - Region Allocation',
  compareData = [],
}: CompareRegionAllocationsProps) {
  if (!Array.isArray(compareData) || compareData.length === 0) return <></>;

  const hidePage = compareData.every(el => el.length === 0);
  if (hidePage) return <></>;

  return (
    <ProposalPage headerTitle={headerTitle}>
      <Text
        style={tw(
          'font-inter font-medium text-default text-sm leading-[1.059rem] pb-2',
        )}
      >
        Region Allocation
      </Text>
      <Text style={tw('font-inter text-xs leading-[1.375rem] text-neutral600')}>
        Comparing region allocations across Super-regions (Developed Markets and
        Emerging Markets) to see how your holdings and its underlying exposures
        will change with the recommendation.
      </Text>
      <View style={tw('flex flex-row justify-between mt-4')}>
        <Text
          style={tw(
            'w-1/2 text-center font-inter text-sm leading-[1.059rem] text-neutral500 mb-4',
          )}
        >
          Current Portfolio
        </Text>
        <Text
          style={tw(
            'w-1/2 text-center font-inter text-sm leading-[1.059rem] text-neutral500 mb-4 ml-4',
          )}
        >
          Recommended Portfolio
        </Text>
      </View>
      <CompareAllocations
        compareData={compareData}
        allocationType="REGION_ALLOCATION"
      />
      <PageBottomContainer>
        <Disclosure
          minHeight={false}
          text={[
            `Data sourced from Morningstar, Inc. Investing in international securities involves special additional risks. These risks include, but are not limited to, currency risk, political risk, and risk associated with varying accounting standards. Investing in emerging markets may accentuate these risks.`,
            `*Others is any region that Morningstar does not classify as Developed Markets or Emerging Markets.`,
          ]}
        />
      </PageBottomContainer>
    </ProposalPage>
  );
}

export default CompareRegionAllocation;

export const CONTENT = [
  'This report should not be relied on as a substitute for official account statements.',
  'Results do not reflect the impact that material economic and market factors might have had on the advisor’s decision-making if advisor were actually managing client assets.',
  'TIFIN Wealth does not guarantee any minimum level of investment performance or the success of any portfolio or investment strategy. All investments involve risk and investment recommendations will not always be profitable.',
  'Investments in this report are subject to market risk, including the loss of principal.',
  "Past performance is no guarantee of future results. The investment return and principal value of an investment will fluctuate so that an investor's shares, when redeemed, may be worth more or less than their original cost. Current performance may be lower or higher than the performance quoted.",
  'Investing in fixed income securities involves interest rate risk, credit risk, and inflation risk. Interest rate risk is the possibility that bond prices will decrease because of an interest rate increase. When interest rates rise, bond prices and the values of fixed income securities fall. When interest rates fall, bond prices and the values of fixed income securities rise. Credit risk is the risk that a company will not be able to pay its debts, including the interest on its bonds. Inflation risk is the possibility that the interest paid on an investment in bonds will be lower than the inflation rate, decreasing purchasing power.',
  'Cash alternatives typically include money market securities and U.S. treasury bills. Investing in such cash alternatives involves inflation risk. In addition, investments in money market securities may involve credit risk and a risk of principal loss. Because money market securities are neither insured nor guaranteed by the Federal Deposit Insurance Corporation or any other government agency, there is no guarantee the value of your investment will be maintained at $1.00 per share, and your shares, when sold, may be worth more or less than what you originally paid for them. U.S. Treasury bills are subject to market risk if sold prior to maturity. Market risk is the possibility that the value, when sold, might be less than the purchase price.',
  'Investing in stock securities involves volatility risk, market risk, business risk, and industry risk. The prices of stocks fluctuate. Volatility risk is the chance that the value of a stock will fall. Market risk is the chance that the prices of all stocks will fall due to conditions in the economic environment. Business risk is the chance that a specific company’s stock will fall because of issues affecting it. Industry risk is the chance that a set of factors particular to an industry group will adversely affect stock prices within the industry.',
  'Bond Risk Comparison: The value of debt securities may fall when interest rates rise. Debt securities with longer maturities tend to be more sensitive to changes in interest rates, usually making them more volatile than debt securities with shorter maturities. For all bonds there is a risk that the issuer will default.',
  'Corporate Bonds: Corporate bond returns are based on the Bloomberg US Aggregate Bond Index™.',
  'Government Bonds (Generally 15 to 30 years in duration): Long term Government Bonds are measured using a one-bond portfolio with a maturity near 20 years. They are guaranteed by the U. S. Government and, if held to maturity, offer a fixed rate of return and fixed principal value.',
  'Growth: Growth-oriented stocks typically sell at relatively high valuations as compared to other types of stocks. Historically, growth-oriented stocks have been more volatile than value-oriented stocks.',
  'High-Yield: Investments in high-yield bonds are high-risk investments. High-yielding fixed-income securities generally are subject to greater market fluctuations and risk of loss of income and principal than are investments in lower-yielding fixed-income securities. High-yield bonds generally are more susceptible to the risk of default than higher rated bonds.',
  'International: International investing involves special risks such as currency fluctuation, lower liquidity, political and economic uncertainties, and differences in accounting standards. Risks of foreign investing are generally intensified for investments in emerging markets.',
  'Internet Stocks: Internet stocks may offer greater potential for growth, but there are additional market and business risk associated with them, such as a greater degree of change in earnings and greater short-term volatility.',
  'Large Cap Funds: A large capitalization, or large-cap, fund is a mutual fund that invests primarily in companies that have stock market capitalization of $5 billion or more. Capitalization is calculated by multiplying the price of a stock by its outstanding shares. Large-cap companies typically have annual revenues in excess of $2 billion. General Motors and Exxon are examples of large capitalization stocks. The performance of large-cap mutual funds is comparable to that of major stock market barometers such as the S&P 500 and the Dow Jones Industrial Average. In addition, the return volatility of large-cap funds tend to be comparable to that of the broader market. Large-cap funds are often suitable for long-term investors who require a somewhat conservative exposure to the equity markets. Large-cap funds also have different investment objectives, and often make up a significant portion of 401(k) plans, IRAs, and pension accounts.',
  'Mid-Sized: Mid-Sized Company Stocks. In exchange for higher growth potential, investing in stocks of small- and mid-sized companies may entail greater price volatility and less liquidity than investing in stocks of larger companies. Mid-Cap Securities of mid-sized companies may be more susceptible to price swings and are less liquid than investments in larger companies.',
  'Real Estate Investment Trusts: REITs involve risks such as refinancing, economic impact on the industry, changes in property value,dependency on management skills and risks similar to small company investing.',
  'Sector Funds: Sector funds may involve greater than average risk and are often more volatile than funds holding a diversified portfolio of stocks in many industries. Examples include banking, biotechnology, chemicals, energy, environmental services, natural resources, precious metals, technology, telecommunications, and utilities.',
  'Small Cap: Small company stocks offer greater potential for growth, but there are additional market and business risks associated with them, such as low trading volumes, a greater degree of change in earnings and greater short-term volatility. Stocks of smaller companies tend to be less liquid, more volatile and sensitive to unexpected changes in interest rates.',
  "Technology Fund: The principal risks of investing in the Fund are those generally attributed to sector investing. Because the Fund's investments are concentrated in information technology industries, the value of its shares will be especially affected by factors particular to those industries and may fluctuate more widely than the value of shares of more diversified portfolios.",
  'Treasury Bills (Generally 90 days to one year in duration): U. S. Treasury bill returns are based on an index reflecting the monthly return for a one-bill portfolio having a term of not less than one month to maturity. Principal and interest payments on Treasury bills are backed by the U.S. Government or its agencies.',
  'U.S. Treasury 10-year Notes: U.S. Treasury 10-year notes are intermediate securities with a 10-year maturity and are secured by the full faith and credit of the U.S. government.',
  'Currency prices may be highly volatile. Price movements for currencies are influenced by, among other things: changing supply and demand; trade, fiscal, monetary, and exchange control policies of governments; U.S. and foreign political and economic events; changes in national and international interest rates and inflation; currency devaluation; and sentiment of the marketplace. None of these factors can be controlled by clients or TIFIN or any of its affiliates, and no assurance can be given that clients will not incur losses from such events.',
  'This portfolio may contain investments requiring the delivery of a prospectus. See fund prospectus for details.',
  'Mutual funds may contain sales charges, expenses, management fees, and breakpoint discounts (quantity discounts); which vary from fund to fund. Therefore, you should discuss these issues with your financial representative and review each mutual fund’s prospectus and statement of additional information to get the specific information regarding the charges and breakpoint discounts associated with a particular mutual fund.',
  'ETFs trade like a stock, and ETFs may trade for less than their net asset value. See prospectus for details.',
  'Tax Considerations: Investing may generate tax consequences which are not incorporated into this report. Rebalancing can entail transaction costs and tax consequences that should be considered when determining a rebalancing strategy.',
  'TIFIN aggregates Capital Market Assumptions (CMAs) reported by the top asset management institutions and uses Geometric Brownian Motion (GBM) to project Current and Recommended portfolio balances for 10 years. Geometric Brownian motion is used to describe random movements of financial assets over time where the size of the variations is proportional to the current value of the asset. GBM is the industry standard model used in finance to forecast security returns. The benefits of GBM over Monte Carlo (making standard industry assumptions such as normality and static volatility) is speed (runs much faster) and will return the same results every time due to forecasting every point on the distribution versus making a random distribution. Potential drawbacks of GBM (and Monte Carlo) is the assumption of constant volatility and normal distribution; however, over long enough time periods these potential drawbacks become mitigated.',
];

import React, { useMemo } from 'react';
import { Text, View } from '@react-pdf/renderer';

import RiskSliderGradient from './RiskBandSliderGradient';

import { tw } from 'utils/tailwindSetup';
import { RiskBandProps } from './types';
import { riskCapacityColor, riskPreferenceColor } from './colors';
import RiskScoreCircle from '../RiskScoreCircle';
import RenderToImage from '../RenderToImage';

const baseValueStyle = tw(
  'font-inter text-default font-medium text-base leading-6 mt-1',
);

function RiskBand({
  riskData: { riskCapacity = 0, riskPreference = 0 },
  riskScores = [],
}: RiskBandProps) {
  const isCapacityLowerThanPreferenceValue = useMemo(() => {
    return riskCapacity < riskPreference ?? false;
  }, [riskCapacity, riskPreference]);

  const riskBandWidth = isCapacityLowerThanPreferenceValue
    ? riskPreference - riskCapacity
    : riskCapacity - riskPreference;
  const marginLeft = isCapacityLowerThanPreferenceValue
    ? `${riskCapacity}%`
    : `${riskPreference}%`;

  const position = isCapacityLowerThanPreferenceValue ? '-right' : '-left';
  const positionInverse = isCapacityLowerThanPreferenceValue
    ? '-left'
    : '-right';

  return (
    <View
      style={tw(
        'w-full bg-neutral50 rounded-3xl p-2.5 flex flex-row items-center mt-8',
      )}
    >
      <Text style={baseValueStyle}>0</Text>
      <View
        style={tw(
          'relative h-1 w-full bg-neutral200 border border-neutral200 rounded-sm my-0 mx-2',
        )}
      >
        <View style={tw(`relative min-h-[0.1325rem] w-full`)}>
          <View
            style={tw(
              `relative min-h-1 ${riskBandWidth ? `w-[${riskBandWidth}%]` : 'w-0'} ml-[${marginLeft}]`,
            )}
          >
            {riskBandWidth && (
              <RenderToImage>
                <RiskSliderGradient
                  isCapacityLowerThanPreferenceValue={
                    isCapacityLowerThanPreferenceValue
                  }
                />
              </RenderToImage>
            )}

            {/* indicators */}
            <View
              style={tw(
                `w-2 h-2 absolute -top-[0.1875rem] rounded ${position}-0.5 bg-[${riskPreferenceColor}]`,
              )}
            />
            <View
              style={tw(
                `w-2 h-2 absolute -top-[0.1875rem] rounded ${positionInverse}-0.5 bg-[${riskCapacityColor}]`,
              )}
            />
            <View
              style={tw(
                `absolute top-3 h-4 w-4 bg-[${riskPreferenceColor}] rounded-full rounded-tl-none rotate-45 ${position}-1.5`,
              )}
            />
            <View
              style={tw(
                `absolute bottom-3 h-4 w-4 bg-[${riskCapacityColor}] rounded-full rounded-br-none rotate-45 ${positionInverse}-1.5`,
              )}
            />
            {/* risk preference and risk capacity text */}
            <Text
              style={tw(
                `w-5 absolute top-[1.875rem] text-[${riskPreferenceColor}] font-semibold text-[1.75rem] ${position}-0`,
              )}
            >
              {riskPreference ?? 0}
            </Text>
            <Text
              style={tw(
                `w-32 absolute top-[3.75rem] font-inter text-[${riskPreferenceColor}] font-medium text-base leading-[1.52rem] ${position}-16`,
              )}
            >
              Risk Preference
            </Text>
            <Text
              style={tw(
                `w-5 absolute bottom-[1.875rem] text-[${riskCapacityColor}] font-semibold text-[1.75rem] ${positionInverse}-2`,
              )}
            >
              {riskCapacity ?? 0}
            </Text>
            <Text
              style={tw(
                `w-28 absolute bottom-[3.75rem] font-inter text-[${riskCapacityColor}] font-medium text-base leading-[1.52rem] ${positionInverse}-14`,
              )}
            >
              Risk Capacity
            </Text>
          </View>

          {Array.isArray(riskScores) &&
            riskScores.length > 0 &&
            riskScores.map((riskScore, index) => {
              const score = riskScore?.value >= 100 ? 100 : riskScore?.value;
              return (
                <View
                  key={index}
                  style={tw(
                    `absolute w-[1.625rem] h-[1.625rem] -top-3 left-[${score - 4}%]`,
                  )}
                >
                  <RiskScoreCircle
                    score={score ?? 0}
                    textSize={14}
                    customPieChartFillColor={riskScore?.color || '#7B73E4'}
                  />
                </View>
              );
            })}
        </View>
      </View>

      <Text style={baseValueStyle}>100</Text>
    </View>
  );
}

export default RiskBand;

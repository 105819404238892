export const getMinWidthForHoldings = (type: string) => {
    switch (type) {
        case 'ASSET CLASS':
            return '150px';
        case 'ASSET TYPE':
            return '150px';
        case 'ASSET':
            return '150px';
        case 'EXPECTED RETURN':
            return '150px';
        case 'VOLATILITY':
            return '150px';
        default:
            return '150px';
    }
};